import { FunctionComponent, ReactNode, useRef } from 'react'
import styled from '@emotion/styled'
import moment, { Moment } from 'moment'
import {
  SingleDatePicker as SingleDatePickerBase,
  SingleDatePickerShape as SingleDatePickerShapeBase,
} from 'react-dates'
import { useTranslation } from 'react-i18next'
import { Grid, SvgIcon, Text, useClickOutsideEvent } from '@/tbui'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const DEFAULT_DATE_FORMAT = 'dddd, MMMM D, YYYY'

export type SingleDatePickerProps = SingleDatePickerShapeBase & {
  // TODO: Figure out requirements for calendar. Revisit props.
  date: Moment | null
  focused: boolean
  id: string
  availableDays: string[]
  customInput?: ReactNode
  disabled?: boolean
  onFocusChange: (arg: { focused: boolean | null }) => void
  onDateChange: (date: Moment | null) => void
  onCancel: () => void
}

const ArrowPrevWrapper = styled.div`
  position: absolute;
  left: 35px;
  top: 20px;
`

const ArrowNextWrapper = styled.div`
  position: absolute;
  right: 35px;
  top: 20px;
`

const StyledDateInputWrapper = styled(Grid)`
  justify-content: space-between;
`

const StyledDate = styled(Grid)`
  grid-template-areas: 'label icon' 'value icon';
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  > h6 {
    grid-area: label;
  }
  > p {
    grid-area: value;
  }
  > i {
    grid-area: icon;
  }
`

// Calendar styles override
const StyledDatePickerWrapper = styled.div`
  .CalendarDay__default {
    border: none;
    border-radius: ${(props) => props.theme.shape.RADIUS};
    color: ${(props) => props.theme.palette.TEXT};
    font-size: 11.2px;
  }
  .CalendarDay__default:hover {
    color: ${(props) => props.theme.palette.TEXT_SECONDARY};
    background: ${(props) => props.theme.palette.SECONDARY};
  }
  .SingleDatePickerInput {
    font-size: 16px;
    border: 0;
    background: none;
  }
  .SingleDatePicker_picker {
    z-index: 10;
    background: transparent;
  }
  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover,
  .CalendarDay__blocked_out_of_range:active {
    border: 0;
    color: ${(props) => props.theme.palette.GRAY_2};
  }
  .CalendarMonth_caption {
    font-family: ${(props) => props.theme.font.FAMILY_BOLD};
    font-size: 12.8px;
  }
  .DayPicker_weekHeader_ul {
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.TEXT};
  }
  .CalendarDay__today {
    color: ${(props) => props.theme.palette.TEXT};
    background: ${(props) => props.theme.palette.GRAY_1};
    border-radius: ${(props) => props.theme.shape.RADIUS};
  }
  .CalendarMonth_table {
    margin-top: 12px;
  }
  .DateInput_fang {
    display: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${(props) => props.theme.palette.PRIMARY};
    border-radius: ${(props) => props.theme.shape.RADIUS};
    color: ${(props) => props.theme.palette.TEXT_PRIMARY};
    border: none;
  }
  .DayPicker {
    min-height: 355px;
    padding-top: 25px;
    bottom: 310px;
    left: 120px;
    @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
      left: 3%;
    }
  }
  .DateInput {
    display: none;
  }
  .SingleDatePickerInput_calendarIcon {
    width: 100%;
    padding: 0.5rem 21px;
    margin: 0px;
  }
`

const SingleDatePicker: FunctionComponent<SingleDatePickerProps> = ({
  date,
  focused,
  id,
  availableDays,
  customInput,
  disabled,
  onDateChange,
  onFocusChange,
  onCancel,
}) => {
  const { t } = useTranslation()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  useClickOutsideEvent(wrapperRef, onCancel)

  const translateDatePicker = ({
    startDate,
  }: {
    startDate: string
  }): { [localeKey: string]: string } => {
    const chooseAvailableStartDate = (dateStr: string): string =>
      t('react_dates_choose_available_start_date', { dateStr })
    const dateIsSelectedAsStartDate = (dateStr: string): string =>
      t('react_dates_date_is_selected_as_start_date', { dateStr })

    return {
      calendarLabel: t('calendar'),
      roleDescription: t('datepicker'),
      closeDatePicker: t('close'),
      clearDates: t('react_dates_clear_dates'),
      focusStartDate: t('react_dates_calendar_label'),
      jumpToPrevMonth: t('react_dates_jump_to_prev_month'),
      jumpToNextMonth: t('react_dates_jump_to_next_month'),
      keyboardShortcuts: t('react_dates_keyboard_shortcuts'),
      showKeyboardShortcutsPanel: t('react_dates_show_keyboard_shortcuts_panel'),
      hideKeyboardShortcutsPanel: t('react_dates_hide_keyboard_shortcuts_panel'),
      openThisPanel: t('react_dates_open_this_panel'),
      enterKey: t('react_dates_enter_key'),
      leftArrowRightArrow: t('react_dates_left_arrow_right_arrow'),
      upArrowDownArrow: t('react_dates_up_arrow_down_arrow'),
      pageUpPageDown: t('react_dates_page_up_page_down'),
      homeEnd: t('react_dates_home_end'),
      escape: t('react_dates_escape'),
      questionMark: t('react_dates_question_mark'),
      selectFocusedDate: t('react_dates_select_focused_date'),
      moveFocusByOneDay: t('react_dates_move_focus_by_one_day'),
      moveFocusByOneWeek: t('react_dates_move_focus_by_one_week'),
      moveFocusByOneMonth: t('react_dates_move_focus_by_one_month'),
      moveFocustoStartAndEndOfWeek: t('react_dates_move_focus_to_start_and_end_of_week'),
      returnFocusToInput: t('react_dates_return_focus_to_input'),
      keyboardForwardNavigationInstructions: t(
        'react_dates_keyboard_forward_navigation_instructions'
      ),
      keyboardBackwardNavigationInstructions: t(
        'react_dates_keyboard_backward_navigation_instructions'
      ),
      chooseAvailableStartDate: chooseAvailableStartDate(startDate),
      dateIsSelectedAsStartDate: dateIsSelectedAsStartDate(startDate),
    }
  }

  const renderDefaultInput = (): ReactNode => {
    return (
      <StyledDateInputWrapper
        template="1fr auto"
        justify="center"
        align="center"
        data-test="date-picker-input"
      >
        <StyledDate template="1fr auto">
          <Text type="H6" color="GRAY_2">
            {t('single_date_picker.label')}
          </Text>
          <Text fontSize="16px" data-test="formatted-date">
            {moment(date).format(DEFAULT_DATE_FORMAT)}
          </Text>
          <SvgIcon name="calendar" color="PRIMARY" fontSize="16px" />
        </StyledDate>
      </StyledDateInputWrapper>
    )
  }

  const isOutsideRange = (day: Moment): boolean => {
    return !availableDays.includes(day.format('YYYY-MM-DD'))
  }

  return (
    <StyledDatePickerWrapper ref={wrapperRef}>
      <SingleDatePickerBase
        keepOpenOnDateSelect
        displayFormat={DEFAULT_DATE_FORMAT}
        disabled={disabled}
        enableOutsideDays
        date={date}
        id={id}
        onDateChange={onDateChange}
        customInputIcon={customInput ?? renderDefaultInput()}
        phrases={translateDatePicker({
          startDate: `${moment(date).format(DEFAULT_DATE_FORMAT)}`,
        })}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        focused={focused}
        onFocusChange={onFocusChange}
        isOutsideRange={isOutsideRange}
        block
        navPrev={
          <ArrowPrevWrapper role="tabpanel">
            <SvgIcon name="chevron_left" fontSize="11px" color="PRIMARY" />
          </ArrowPrevWrapper>
        }
        navNext={
          <ArrowNextWrapper role="tabpanel">
            <SvgIcon name="chevron_right" fontSize="11px" color="PRIMARY" />
          </ArrowNextWrapper>
        }
        inputIconPosition="after"
        // Controls size of day cell in the calendar
        daySize={40}
        disableScroll
      />
    </StyledDatePickerWrapper>
  )
}
export default SingleDatePicker
