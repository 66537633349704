import { FunctionComponent, MouseEvent } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import PopupModalTemplate from '@/components/PopupModalTemplate'
import type { Bill } from '@/libs/helpers/apiClient'
import { useDebounce } from '@/libs/helpers/hooks/useDebounce'
import { OrderMethod } from '@/libs/helpers/utils'
import { useModal } from '@/providers/modal'
import { Button, Text } from '@/tbui'
import { CartWidgetVariant } from './CartItem'

const headerStyles = (theme: Theme): string => css`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 0 0 1.75rem;
  align-content: center;

  @media (min-width: ${theme.breakpoints.SM}) {
    padding-top: 1rem;
  }
`

const StyledTitle = styled(Text)`
  ${(props) =>
    props.theme.mixin.fluidRange({
      prop: 'fontSize',
      fromSize: '20px',
      toSize: '30px',
    })};
`

export interface CartHeaderProps {
  variant: CartWidgetVariant
  cart: {
    bill: Bill
    isCartLoading: boolean
    orderMethod: OrderMethod
  }
  onClearCart: () => Promise<void>
  venueXRefID: string
}

const CartHeader: FunctionComponent<CartHeaderProps> = ({
  cart,
  variant,
  onClearCart,
  venueXRefID,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModal()
  const theme = useTheme()

  const orderMethod = cart.orderMethod
  const cartContext = variant.toLowerCase()

  const handleDelayedRedirect = useDebounce(() => {
    router.push(
      '/[orderMethod]/[venueXRefID]/menu',
      `/${orderMethod.toLowerCase()}/${venueXRefID}/menu`
    )
  }, 250)

  const handleClearCartConfirm = async (): Promise<void> => {
    await onClearCart()
    // On clear cart, always push to menu
    // The router is smart enough to do nothing if we are already at the menu page
    closeModal()
    // NOTE: This DEBOUNCED redirect is cause of race condition when updating cart
    // after we got redirect from checkout to menu, we can have old cart items without debounce
    handleDelayedRedirect()
  }

  const renderClearCartModal = (): void => {
    openModal(
      <PopupModalTemplate
        name="clear-cart"
        callToAction={t('modal.clear_cart_confirm.submit')}
        message={t('modal.clear_cart_confirm.message')}
        title={t('modal.clear_cart_confirm.title')}
        onSubmit={handleClearCartConfirm}
        onClose={closeModal}
      />,
      { variant: 'SMALL' }
    )
  }

  return (
    <div data-test="cart-header" className={headerStyles(theme)}>
      <StyledTitle type="H1">
        {t('cart_widget.header.title', {
          context: cartContext,
        })}
      </StyledTitle>
      <Button
        onClick={async (e: MouseEvent) => {
          e.preventDefault()
          renderClearCartModal()
        }}
        data-test="clear-cart"
        disabled={cart.isCartLoading}
        variant="LINK"
        type="button"
      >
        {t('cart_widget.header.button_clear')}
      </Button>
    </div>
  )
}

export { CartHeader }
