import { useState, FunctionComponent, ReactNode } from 'react'
import { Moment } from 'moment'
import SingleDatePicker from './SingleDatePicker'

interface SingleDatePickerProps {
  id: string
  availableDays: string[]
  selectedDate: Moment | null
  onSelectDate: (date: Moment) => void
  customInput?: ReactNode
  disabled?: boolean
}

const SingleDateCalendar: FunctionComponent<SingleDatePickerProps> = ({
  id,
  availableDays,
  selectedDate,
  onSelectDate,
  customInput,
  disabled,
}) => {
  const [focusedInput, setFocusedInput] = useState({ focused: false })

  const onCancel = (): void => {
    return setFocusedInput({ focused: false })
  }

  return (
    <SingleDatePicker
      availableDays={availableDays}
      date={selectedDate}
      onDateChange={(date: Moment | null) => {
        if (date != null) {
          setFocusedInput({ focused: false })
          onSelectDate(date)
        }
      }}
      focused={focusedInput.focused}
      onFocusChange={(item) => {
        setFocusedInput({ focused: item != null })
      }}
      id={id}
      onCancel={onCancel}
      customInput={customInput}
      disabled={disabled}
    />
  )
}
export default SingleDateCalendar
