import { FunctionComponent, useMemo, useRef } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import InfoBanner from '@/components/InfoBanner'
import CustomSelect from '@/components/SearchInputByAddress/DropDownSelector/CustomSelect'
import SingleDateCalendar from '@/components/SingleDateCalendar'
import TileSelect from '@/components/TileSelect'
import { Venue } from '@/libs/helpers/adapters'
import { ScheduleTimeslotWithReason, ScheduleUnavailableReason } from '@/libs/helpers/apiClient'
import type { OptionsType } from '@/libs/helpers/utils'
import { Grid, SvgIcon, Text } from '@/tbui'

const orderTimeTileStyle = (theme: Theme): string => css`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;

  @media (max-width: ${theme.breakpoints.SM}) {
    grid-template-columns: 1fr;
  }
`

export enum OrderTime {
  ASAP = 'asap',
  SCHEDULED = 'scheduled',
}

export interface SchedulingState {
  selectedOrderTime: OrderTime
  availableTimes: OptionsType[]
  unavailableTimes: Pick<ScheduleTimeslotWithReason, 'time' | 'unavailabilityReason'>[]
  selectedDate: Moment
  selectedTime: string | null
}

export interface ScheduledOrderSelectorProps {
  venue: Venue
  isLoading?: boolean
  disabled?: boolean
  schedulingForm: SchedulingState
  onSelectOrderTime: (orderTime: OrderTime) => void
  onSelectDate: (date: Moment) => void
  onSelectTime: (time: string) => void
  orderThrottlingLimitReached: boolean
}

// STYLES
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const StyledDiv = styled.div`
  background: ${(props) => props.theme.palette.WHITE};
  display: grid;
  align-items: center;
  gap: 0.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;

  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    grid-template-columns: auto;
  }
`

const StyledWrapper = styled(Grid)<{ hasError?: boolean }>`
  border: ${(props) => props.theme.shape.BORDER};
  border-color: ${(props) =>
    props.hasError ? props.theme.palette.ERROR : props.theme.palette.GRAY_2};
  border-radius: ${(props) => props.theme.shape.RADIUS};
`

const StyledDateWrapper = styled(Grid)`
  position: relative;
`

const CustomSelectStyledWrapper = styled.div`
  border-color: ${(props) => props.theme.palette.GRAY_2};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  height: 40px;
  grid-template-columns: auto 1fr;
  place-items: center;
  position: relative;
  padding: 5px;
  gap: 5px;
`

const ScheduledOrderSelector: FunctionComponent<ScheduledOrderSelectorProps> = ({
  venue,
  isLoading,
  disabled,
  schedulingForm,
  onSelectOrderTime,
  onSelectDate,
  onSelectTime,
  orderThrottlingLimitReached,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const { selectedOrderTime, selectedTime, selectedDate, availableTimes, unavailableTimes } =
    schedulingForm

  const orderTimeOptions = Object.keys(OrderTime).map((option) => ({
    value: option.toLocaleLowerCase(),
    label: t(`scheduled_order_selector.label_${option.toLocaleLowerCase()}`),
    disabled:
      disabled || (option.toLocaleLowerCase() === OrderTime.ASAP && orderThrottlingLimitReached),
  }))

  // Always want max scheduling days + 1 to account for current day
  const availableDays = [...Array(venue.maxSchedulingDays + 1).keys()].map(
    (daysToAdd) => DateTime.local().plus({ day: daysToAdd }).toISODate() ?? ''
  )

  const handleTimeChange = (time: string): void => {
    onSelectTime(time)
  }

  const SelectedDateWarningMessage = useMemo((): JSX.Element | null => {
    const isVenueClosed =
      unavailableTimes.every(
        ({ unavailabilityReason }) =>
          unavailabilityReason === ScheduleUnavailableReason.VENUE_CLOSURE
      ) && availableTimes.length === 0
    const isAnyTimeslotThrottled = unavailableTimes.some(
      ({ unavailabilityReason }) =>
        unavailabilityReason === ScheduleUnavailableReason.ORDERS_LIMIT_REACHED
    )
    const areAllTimeslotsThrottled =
      unavailableTimes.every(
        ({ unavailabilityReason }) =>
          unavailabilityReason === ScheduleUnavailableReason.ORDERS_LIMIT_REACHED ||
          // Needed here because it could be possible for the venue to be closed during certain timeslots while the rest are throttled
          unavailabilityReason === ScheduleUnavailableReason.VENUE_CLOSURE
      ) && availableTimes.length === 0

    // Venue is closed for the day
    if (isVenueClosed) {
      return (
        <InfoBanner
          type="warning"
          message={t('error.message_err_ordering_not_available_on_date')}
        />
      )
    }

    // All timeslots are throttled
    if (areAllTimeslotsThrottled) {
      return <InfoBanner type="warning" message={t('order_throttling.all_timeslots_unavailable')} />
    }

    if (orderThrottlingLimitReached) {
      return isAnyTimeslotThrottled ? (
        // Venue is currently throttled for asap, and some timeslots are throttled
        <InfoBanner
          type="warning"
          message={t('order_throttling.asap_limit_reached_and_some_timeslots_unavailable')}
        />
      ) : (
        // Venue is currently throttled for asap
        <InfoBanner
          type="warning"
          message={t('order_throttling.asap_limit_reached_with_schedule_enabled')}
        />
      )
    }

    // Venue is NOT throttled for asap, and some scheduled timeslots are throttled
    if (isAnyTimeslotThrottled) {
      return (
        <InfoBanner type="warning" message={t('order_throttling.some_timeslots_unavailable')} />
      )
    }

    return null
  }, [availableTimes, orderThrottlingLimitReached, t, unavailableTimes])

  return (
    <Grid data-test="scheduled-order-selector" m="0 0 0.75rem" gap="1rem">
      {orderTimeOptions.length > 1 && (
        <StyledDiv>
          <Grid template="auto 1fr" gap=".5rem">
            <SvgIcon name="time" color={disabled ? 'GRAY_3' : 'PRIMARY'} fontSize="20px" />
            <Text type="H4" bold color={disabled ? 'GRAY_3' : undefined}>
              {t('scheduled_order_selector.when_header')}
            </Text>
          </Grid>
          <TileSelect
            showRadioButton
            name="scheduled-order-selector-tiles"
            options={orderTimeOptions}
            onChange={(option) => {
              onSelectOrderTime(option as OrderTime)
            }}
            className={orderTimeTileStyle(theme)}
            value={selectedOrderTime}
            disabled={disabled || isLoading}
          />
        </StyledDiv>
      )}
      {!disabled && selectedOrderTime === OrderTime.SCHEDULED && (
        <>
          {!isLoading && SelectedDateWarningMessage}
          <StyledContainer>
            <StyledWrapper hasError={!isLoading && availableTimes.length === 0}>
              <StyledDateWrapper>
                <SingleDateCalendar
                  id="scheduled-order-date"
                  selectedDate={selectedDate}
                  availableDays={availableDays}
                  onSelectDate={onSelectDate}
                  disabled={isLoading}
                />
              </StyledDateWrapper>
            </StyledWrapper>
            {!isLoading && availableTimes.length === 0 && (
              <Text type="P" color="ERROR_TEXT">
                {t('error.message_err_please_select_a_different_date')}
              </Text>
            )}
            <StyledWrapper>
              <Grid p=".5rem .5rem">
                <CustomSelectStyledWrapper ref={wrapperRef}>
                  <CustomSelect
                    wrapperRef={wrapperRef}
                    onChange={handleTimeChange}
                    groupId="schedule-order-time-dropdown"
                    options={availableTimes}
                    minWidth={120}
                    popupMaxHeight={200}
                    value={selectedTime ?? ''}
                    placeholder={t('scheduled_order_selector.time_label')}
                    dropup
                    styleOnly={isLoading}
                  />
                </CustomSelectStyledWrapper>
              </Grid>
            </StyledWrapper>
          </StyledContainer>
        </>
      )}
    </Grid>
  )
}

export default ScheduledOrderSelector
