import { FunctionComponent, MouseEvent, useEffect, useMemo, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { DateTime } from 'luxon'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import PopupModalTemplate from '@/components/PopupModalTemplate'
import TileSelect, { TileSelectProps } from '@/components/TileSelect'
import type { MenuSource, Venue } from '@/libs/helpers/adapters'
import {
  CartUpdateRequest,
  ScheduleTimeslotWithReason,
  getSchedule,
} from '@/libs/helpers/apiClient'
import {
  CartState,
  ConsumerAddress,
  OrderMethod,
  buildSchedulingTimes,
  formatASAPOTLimitReachedMessage,
  getOrderMethodSettings,
  getRelativeTimeToNextAvailableInterval,
} from '@/libs/helpers/utils'
import { useError } from '@/providers/error'
import { OrderingAddress, useGoogleMaps } from '@/providers/googleMaps'
import { useModal } from '@/providers/modal'
import { useUserLanguage } from '@/providers/userLanguage'
import { Button, SvgIcon, Text } from '@/tbui'
import InfoBanner from '../InfoBanner'
import DeliveryAddressInput, { UserDeliveryAddressState } from './DeliveryAddressInput'
import ScheduledOrderSelector, { OrderTime, SchedulingState } from './ScheduledOrderSelector'

const orderMethodTileStyles = (theme: Theme): string => css`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;

  @media (max-width: ${theme.breakpoints.SM}) {
    grid-template-columns: 1fr;
  }
`

const viewMenuStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledSubmitContent = styled.div`
  background: ${(props) => props.theme.palette.WHITE};
  position: sticky;
  bottom: 0;
  padding: 1rem 0;
  display: grid;

  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    box-shadow: ${(props) => props.theme.shape.SHADOW_TOP};
  }
`

interface OrderMethodSelectorProps {
  venue: Venue
  isLoading?: boolean
  showEditDetails?: boolean
  savedAddresses: ConsumerAddress[]
  menuSource: MenuSource
  cart: CartState
  onUpdateMe: ({ cart }: { cart: Partial<CartUpdateRequest> }) => Promise<void>
}

const getValidOrderMethod = (
  venueOrderingMethods: Venue['venueOrderingMethods'],
  currentOrderMethod: OrderMethod | undefined
): OrderMethod => {
  const availableOrderingMethods: OrderMethod[] = []
  if (venueOrderingMethods.some(({ orderMethod }) => orderMethod === OrderMethod.pickup)) {
    availableOrderingMethods.push(OrderMethod.pickup)
  }
  if (venueOrderingMethods.some(({ orderMethod }) => orderMethod === OrderMethod.delivery)) {
    availableOrderingMethods.push(OrderMethod.delivery)
  }
  // Return the current order method if available
  if (currentOrderMethod && availableOrderingMethods.includes(currentOrderMethod)) {
    return currentOrderMethod
  }
  // Otherwise return the first available ordering method. If none are available then we fallback to pickup to appease the UI state
  return availableOrderingMethods[0] ?? OrderMethod.pickup
}

const OrderMethodSelector: FunctionComponent<OrderMethodSelectorProps> = ({
  venue,
  showEditDetails = false,
  isLoading = false,
  menuSource,
  savedAddresses,
  cart,
  onUpdateMe,
}) => {
  const { setModalError } = useError()
  const { t } = useTranslation()
  const { userLanguage } = useUserLanguage()
  const { openModal, closeModal } = useModal()
  const theme = useTheme()

  const [{ orderingAddress: orderingAddressInGoogleMap }, { clearUserLocation }] = useGoogleMaps()
  // assign address from cart only when it's the initial state
  const orderingAddress = orderingAddressInGoogleMap ?? cart.orderingAddress
  const [isContinueButtonLoading, setIsContinueButtonLoading] = useState(false)
  const [isScheduledSectionLoading, setIsScheduledSectionLoading] = useState(false)
  const [orderMethod, setOrderMethod] = useState<OrderMethod>(
    getValidOrderMethod(venue.venueOrderingMethods, cart.orderMethod)
  )
  const isDelivery = orderMethod === OrderMethod.delivery
  const [userDeliveryAddress, setUserDeliveryAddress] = useState<UserDeliveryAddressState>(() => {
    const USER_DELIVERY_ADDRESS_INITIAL_STATE: UserDeliveryAddressState = {
      isValidAddress: false,
      deliveryAddress: '',
      aptNum: '',
      useLocation: false,
      addressXRefID: cart.orderingAddress?.addressXRefID ?? null,
      saveDeliveryAddress: false,
      googlePlaceID: '',
    }
    if (orderingAddress == null) {
      return {
        ...USER_DELIVERY_ADDRESS_INITIAL_STATE,
        isValidAddress: false,
      }
    }
    return {
      ...USER_DELIVERY_ADDRESS_INITIAL_STATE,
      deliveryAddress: orderingAddress.formattedAddress,
      aptNum:
        orderingAddress.address2 != null && orderingAddress.address2 !== ''
          ? orderingAddress.address2
          : USER_DELIVERY_ADDRESS_INITIAL_STATE.aptNum,
      isValidAddress: true,
      googlePlaceID: orderingAddress.googlePlaceID,
    }
  })
  const { isValidAddress, aptNum, saveDeliveryAddress, addressXRefID } = userDeliveryAddress

  const {
    isSchedulingAvailable,
    isASAPAvailable,
    nextAvailable,
    orderThrottlingLimitReached,
    weeklySchedule,
  } = useMemo(
    () => getOrderMethodSettings(venue.venueOrderingMethods, orderMethod),
    [orderMethod, venue]
  )

  // Scheduled Order States
  const [schedulingForm, setSchedulingForm] = useState<SchedulingState>(() => {
    const state: SchedulingState = {
      selectedOrderTime:
        cart.scheduledFor != null || (orderThrottlingLimitReached && isSchedulingAvailable)
          ? OrderTime.SCHEDULED
          : OrderTime.ASAP,
      selectedDate: moment(),
      selectedTime: '',
      availableTimes: [],
      unavailableTimes: [],
    }

    if (
      !cart.isCartLoading &&
      cart.scheduledFor == null &&
      (!isASAPAvailable || orderThrottlingLimitReached) &&
      isSchedulingAvailable &&
      nextAvailable != null
    ) {
      const scheduledDate = DateTime.fromISO(nextAvailable, {
        zone: 'utc',
      })
      state.selectedTime = scheduledDate.toLocal().toFormat('TT')
      state.selectedDate = moment(scheduledDate.toLocal().toISODate())
    }

    if (!cart.isCartLoading) {
      if (cart.scheduledFor != null && isSchedulingAvailable) {
        const scheduledDate = DateTime.fromISO(cart.scheduledFor, {
          zone: 'utc',
        })
        state.selectedTime = scheduledDate.toLocal().toFormat('TT')
        state.selectedDate = moment(scheduledDate.toLocal().toISODate())
      } else if (!orderThrottlingLimitReached) {
        state.selectedOrderTime = OrderTime.ASAP
      }
    }
    return state
  })
  const { selectedDate, selectedTime, selectedOrderTime, availableTimes } = schedulingForm
  const isScheduled = selectedOrderTime === OrderTime.SCHEDULED
  const luxonTime = selectedTime ? DateTime.fromISO(selectedTime) : null
  const scheduledFor = luxonTime
    ? DateTime.fromISO(selectedDate.format()).set({
        hour: luxonTime.get('hour'),
        minute: luxonTime.get('minute'),
        second: 0,
      })
    : null

  const orderMethodOptions: TileSelectProps['options'] =
    venue.venueOrderingMethods.map(({ orderMethod: method }) => ({
      value: method,
      label: t('order_method_selector.label', {
        context: method.toLowerCase(),
      }),
    })) ?? []

  // VALIDATORS
  const isScheduledFormValid =
    selectedDate != null && selectedTime != null && selectedTime !== '' && availableTimes.length > 0
  const isButtonDisabled = (isDelivery && !isValidAddress) || (isScheduled && !isScheduledFormValid)
  const isOTLimitReached = !isSchedulingAvailable && orderThrottlingLimitReached

  const getScheduleForOrder = async (
    newOrderMethod: OrderMethod,
    newOrderDate: string,
    newOrderingAddress: OrderingAddress | undefined
  ): Promise<ScheduleTimeslotWithReason[]> => {
    setIsScheduledSectionLoading(true)
    try {
      const schedule = await getSchedule(venue.venueXRefID, {
        orderMethod: newOrderMethod,
        orderDate: newOrderDate,
        orderingAddress:
          newOrderMethod === OrderMethod.delivery && isValidAddress
            ? newOrderingAddress
            : undefined,
      })

      setIsScheduledSectionLoading(false)
      return schedule.schedulingTimeslots
    } catch (error) {
      datadogRum.addError(error)
      setModalError(error)
    }

    setIsScheduledSectionLoading(false)
    return []
  }

  const updateSchedulingFormTimes = async (
    newOrderMethod: OrderMethod,
    newOrderTime: OrderTime,
    newOrderDate: Moment,
    newOrderingAddress: OrderingAddress | undefined
  ): Promise<void> => {
    if (newOrderTime === OrderTime.SCHEDULED) {
      const schedule = await getScheduleForOrder(
        newOrderMethod,
        newOrderDate.format('YYYY-MM-DD'),
        newOrderingAddress
      )
      const { available, unavailable } = buildSchedulingTimes(schedule)
      setSchedulingForm((fields) => {
        // If there was a selected time already set, use that (if not possible, set to null), otherwise use first available time
        let newSelectedTime: string | null = available[0]?.value ?? null
        if (fields.selectedTime) {
          newSelectedTime = available.map(({ value }) => value).includes(fields.selectedTime)
            ? fields.selectedTime
            : null
        }
        return {
          ...fields,
          selectedOrderTime: newOrderTime,
          selectedDate: newOrderDate,
          availableTimes: available,
          unavailableTimes: unavailable,
          selectedTime: newSelectedTime,
        }
      })
    } else {
      setSchedulingForm((fields) => ({
        ...fields,
        selectedOrderTime: newOrderTime,
      }))
    }
  }

  const changeOrderTime = async (newOrderTime: OrderTime): Promise<void> => {
    await updateSchedulingFormTimes(orderMethod, newOrderTime, selectedDate, orderingAddress)
  }

  const changeOrderMethod = async (newOrderMethod: OrderMethod): Promise<void> => {
    // When ASAP is only available for one order method (e.g. pickup), and the user switches to the other method (e.g. delivery)
    // that has reached the order throttling limit, then the order time should be switched to schedule
    let newSelectedOrderTime = selectedOrderTime
    if (
      orderThrottlingLimitReached &&
      isSchedulingAvailable &&
      selectedOrderTime === OrderTime.ASAP
    ) {
      newSelectedOrderTime = OrderTime.SCHEDULED
    }

    setOrderMethod(newOrderMethod)
    await updateSchedulingFormTimes(
      newOrderMethod,
      newSelectedOrderTime,
      selectedDate,
      orderingAddress
    )
  }

  const changeScheduleDate = async (newOrderDate: Moment): Promise<void> => {
    await updateSchedulingFormTimes(orderMethod, selectedOrderTime, newOrderDate, orderingAddress)
  }

  const updateCart = async (): Promise<void> => {
    const cartRequest: Partial<CartUpdateRequest> = {
      items: cart.items,
      orderMethod,
      tipAmount: cart.bill.tipTotal,
    }

    if (isDelivery) {
      // Make sure to add aptNum to the ordering address
      const orderingAddressWithAptNumber = orderingAddress ?? cart.orderingAddress
      if (orderingAddressWithAptNumber != null) {
        orderingAddressWithAptNumber.address2 = aptNum
        // if addressXRefID is not null, then address was selected from saved addresses list
        if (addressXRefID != null) {
          orderingAddressWithAptNumber.addressXRefID = addressXRefID
        }

        cartRequest.orderingAddress = {
          ...orderingAddressWithAptNumber,
          saveDeliveryAddress,
        }
      }
    }

    if (isScheduled && scheduledFor != null) {
      cartRequest.scheduledFor = scheduledFor.toISO() ?? ''
    } else {
      setSchedulingForm((fields: SchedulingState) => ({
        ...fields,
        selectedOrderTime: OrderTime.ASAP,
      }))
    }

    await onUpdateMe({
      cart: cartRequest,
    })
    await clearUserLocation()
  }

  const handleSubmit = async (e: MouseEvent): Promise<void> => {
    e.preventDefault()
    setIsContinueButtonLoading(true)
    if (isLoading) {
      return
    }

    if (menuSource !== 'RMM3' || isOTLimitReached) {
      await updateCart()
      setIsContinueButtonLoading(false)
      return
    }

    const hasSwitchedOrderTime =
      (cart.scheduledFor != null && selectedOrderTime === OrderTime.ASAP) ||
      (cart.scheduledFor == null && selectedOrderTime === OrderTime.SCHEDULED)
    let schedule: DateTime | null = null
    if (cart.scheduledFor != null) {
      schedule = DateTime.fromISO(cart.scheduledFor, {
        zone: 'utc',
      })
    }
    const hasChangedScheduledTime = schedule?.toISO() !== scheduledFor?.toISO()
    const isEmptyCart = cart.items.length === 0
    if ((!hasSwitchedOrderTime && !hasChangedScheduledTime) || isEmptyCart) {
      await updateCart()
      setIsContinueButtonLoading(false)
      return
    }

    openModal(
      <PopupModalTemplate
        name="order-time-change-confirmation"
        title={t('order_method_selector.order_time_warning.title')}
        callToAction={t('order_method_selector.submit')}
        message={t('order_method_selector.order_time_warning.message')}
        cancel={t('order_method_selector.cancel')}
        onSubmit={async () => {
          closeModal()
          await updateCart()
        }}
        onClose={() => {
          setIsContinueButtonLoading(false)
          closeModal()
        }}
      />,
      { variant: 'SMALL' }
    )
  }

  useEffect(() => {
    updateSchedulingFormTimes(orderMethod, selectedOrderTime, selectedDate, orderingAddress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (orderingAddress == null) {
      setUserDeliveryAddress((userAddress) => ({
        ...userAddress,
        isValidAddress: false,
      }))
    } else {
      setUserDeliveryAddress((userAddress) => ({
        ...userAddress,
        deliveryAddress: orderingAddress.formattedAddress,
        aptNum:
          orderingAddress.address2 != null && orderingAddress.address2 !== ''
            ? orderingAddress.address2
            : aptNum,
        isValidAddress: true,
        googlePlaceID: orderingAddress.googlePlaceID,
      }))

      updateSchedulingFormTimes(orderMethod, selectedOrderTime, selectedDate, orderingAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderingAddress])

  return (
    <div data-test="order-method-modal">
      <div css={{ display: 'grid', gap: '1.25rem' }}>
        <Text type="H1" align="center">
          {venue.name}
        </Text>
        <Text type="H2" align="center" fontSize="20px">
          {showEditDetails
            ? t('order_method_selector.edit_header')
            : t('order_method_selector.first_selection_header')}
        </Text>
        {isOTLimitReached && (
          <InfoBanner
            type="warning"
            message={formatASAPOTLimitReachedMessage(
              t,
              isSchedulingAvailable,
              isDelivery,
              getRelativeTimeToNextAvailableInterval(
                DateTime.local(),
                15,
                weeklySchedule,
                userLanguage
              )
            )}
          />
        )}
        {orderMethodOptions.length > 1 && (
          <TileSelect
            name="order-method-selector-tiles"
            showRadioButton
            options={orderMethodOptions}
            onChange={(method) => {
              changeOrderMethod(method as OrderMethod)
            }}
            className={orderMethodTileStyles(theme)}
            value={orderMethod}
          />
        )}
        {isDelivery && (
          <DeliveryAddressInput
            userDeliveryAddress={userDeliveryAddress}
            setUserDeliveryAddress={setUserDeliveryAddress}
            savedAddresses={savedAddresses}
          />
        )}
        {isSchedulingAvailable && (
          <ScheduledOrderSelector
            venue={venue}
            isLoading={isScheduledSectionLoading}
            disabled={orderMethod === OrderMethod.delivery && !isValidAddress}
            schedulingForm={schedulingForm}
            onSelectOrderTime={changeOrderTime}
            onSelectDate={changeScheduleDate}
            onSelectTime={(time) => {
              setSchedulingForm((fields) => ({
                ...fields,
                selectedTime: time,
              }))
            }}
            orderThrottlingLimitReached={orderThrottlingLimitReached}
          />
        )}
      </div>
      <StyledSubmitContent>
        <Button
          data-test="order-method-submit"
          disabled={isButtonDisabled}
          onClick={handleSubmit}
          isLoading={!isButtonDisabled && isContinueButtonLoading}
        >
          {!isOTLimitReached ? (
            t('order_method_selector.submit')
          ) : (
            <div className={viewMenuStyles}>
              <SvgIcon
                name="course"
                fontSize="24px"
                color={isButtonDisabled ? 'GRAY_2' : 'TEXT_PRIMARY'}
                style={{ marginRight: '8px' }}
              />
              {t('order_method_selector.view_menu')}
            </div>
          )}
        </Button>
      </StyledSubmitContent>
    </div>
  )
}

export default OrderMethodSelector
