import {
  ChangeEvent,
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import Big from 'big.js'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import type { OperationalMenu, Venue } from '@/libs/helpers/adapters'
import type { CartUpdateRequest } from '@/libs/helpers/apiClient'
import {
  CartItem,
  CartState,
  OrderItem,
  OrderMethod,
  getOrderMethodSettings,
} from '@/libs/helpers/utils'
import { Button, Divider, Grid, Modal, QuantitySelector, Text, TextField } from '@/tbui'
import ModifierGroup from './ModifierGroup'
import { useMenuItem } from './context'

const dividerStyles = css`
  margin: 0 1rem;
  width: unset;
`

// On mobile devices landscape mode, 100vh modal would be cut off by browser menus, this is by design on safari and chrome
// We can mitigate this issue by detecting landscape orientation
const StyledModal = styled(Modal)`
  margin: 1.5rem auto;
  overflow-x: hidden;
  place-self: unset;
  max-height: 100vh;
  overflow-y: auto;
  @media (orientation: landscape) {
    max-height: 90vh;
  }
`

const StyledContent = styled(Grid)`
  word-break: break-word;
`

const StyledFooterContent = styled(Grid)`
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background: ${(props) => props.theme.palette.WHITE};
  position: sticky;
  z-index: 9;
  bottom: 0;
  gap: 58px;
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    gap: 20px;
  }
`

const StyledQuantitySelector = styled(QuantitySelector)`
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakpoints.XS}) {
    gap: 8px;
  }
`

const StyledFooterButton = styled(Button)`
  white-space: normal;
  padding: 6%;
`

const StyledClosedButton = styled(Button)`
  color: ${(props) => props.theme.palette.GRAY_2};
  background-color: ${(props) => props.theme.palette.GRAY_1};
`

const StyledClosedVenueViewCart = styled(Grid)`
  background-color: ${(props) => props.theme.palette.WHITE};
  display: grid;
  position: sticky;
  bottom: 0;
  z-index: 99;
`

interface EditItemModalProps {
  isASAPAvailable: boolean
  isSchedulingAvailable: boolean
  isActive: boolean
  venue: Venue
  operationalMenu: OperationalMenu
  onUpdateMe: ({ cart }: { cart: Partial<CartUpdateRequest> }) => Promise<void>
  cart: CartState
}

const EditItemModal: FunctionComponent<EditItemModalProps> = ({
  isASAPAvailable,
  isSchedulingAvailable,
  isActive,
  cart,
  venue,
  operationalMenu,
  onUpdateMe,
}) => {
  const [quantity, setQuantity] = useState<number>(1)
  const [open, setOpen] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [note, setNote] = useState<string>('')
  const [finalPrice, setFinalPrice] = useState<number>(0)
  const modifierGroups = operationalMenu.modifierGroups ?? {}
  const [windowWidth, setWindowWidth] = useState<number>()

  const {
    setShouldDisplayErrors,
    shouldDisplayErrors,
    setItemIndex,
    setModifiers,
    setMenuItem,
    modifiers,
    itemIndex,
    setErrors,
    menuItem,
    nextRequiredModifierGroupID,
    errors,
  } = useMenuItem()
  const modifierGroupRefs = useRef<Record<string, HTMLDivElement>>({})

  const { t } = useTranslation()
  const isUpdate = itemIndex > -1

  const { orderThrottlingLimitReached } = getOrderMethodSettings(
    venue.venueOrderingMethods,
    cart.orderMethod ?? OrderMethod.pickup
  )

  const isOTLimitReached = !isSchedulingAvailable && orderThrottlingLimitReached

  const handleClose = useMemo(() => {
    return (): void => {
      // Resets while closing
      setShouldDisplayErrors(false)
      setErrors({})
      setMenuItem(null)
      setModifiers([])
      setQuantity(1)
      setItemIndex(-1)
      setOpen(false)
      setNote('')
    }
  }, [setShouldDisplayErrors, setModifiers, setItemIndex, setMenuItem, setErrors])

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    // only start displaying errors & disable submit button once the user has submitted once
    setShouldDisplayErrors(true)
    if (menuItem == null) {
      return
    }
    const itemID = menuItem.XRefID

    if (Object.keys(errors).length > 0) {
      if (
        nextRequiredModifierGroupID != null &&
        modifierGroupRefs.current[nextRequiredModifierGroupID] != null
      ) {
        modifierGroupRefs.current[nextRequiredModifierGroupID].scrollIntoView({
          behavior: 'smooth',
        })
      }
      return
    }

    if (isUpdate) {
      const items = cart.items.map((item: OrderItem, i: number) => {
        if (i !== itemIndex) {
          return item
        }

        return {
          ...item,
          childItems: modifiers,
          quantity: quantity.toString(),
          note,
        }
      })
      onUpdateMe({
        cart: {
          orderMethod: cart.orderMethod,
          orderingAddress: cart.orderingAddress,
          scheduledFor: cart.scheduledFor,
          items,
          tipAmount: cart.bill.tipTotal,
        },
      })

      // Tracks update cart action
      datadogRum.addAction('update-cart', {
        items,
        scheduledFor: cart.scheduledFor,
      })
    } else {
      if (menuItem == null) {
        return
      }
      const items: CartItem[] = [
        ...cart.items,
        {
          id: uuidv4(), // when adding a new cart item, generate a unique uuid
          itemID,
          quantity: quantity.toString(),
          childItems: modifiers,
          note,
          menuGroupXRefID: menuItem.menuGroupXRefID,
        },
      ]

      // Tracks add cart action
      onUpdateMe({
        cart: {
          orderMethod: cart.orderMethod,
          items,
          orderingAddress: cart.orderingAddress,
          scheduledFor: cart.scheduledFor,
          tipAmount: cart.bill.tipTotal,
        },
      })

      datadogRum.addAction('add-to-cart', {
        items,
        scheduledFor: cart.scheduledFor,
      })
    }
    handleClose()
  }

  const noteMaxLength = 255
  const handleChangeNote = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target
    setNote(value == null ? '' : value.slice(0, noteMaxLength))
  }

  const calculatePrice = (): void => {
    if (menuItem == null) {
      return
    }
    // calculates total item price factoring in modifiers and the item quantity
    const price = Big(menuItem.price)
      .plus(
        modifiers
          ? modifiers
              .map((modifier) => modifier.price)
              .reduce((acc: Big, val) => Big(acc).plus(val ?? '0.00'), Big(0))
          : 0
      )
      .mul(quantity)

    setFinalPrice(Number(price))
  }

  useEffect(() => {
    const updateWindowWidth = (): void => {
      // eslint-disable-next-line no-restricted-properties
      setWindowWidth(window.innerWidth)
    }
    updateWindowWidth()
    // eslint-disable-next-line no-restricted-properties
    window.addEventListener('resize', updateWindowWidth)
    // eslint-disable-next-line no-restricted-properties
    return () => window.removeEventListener('resize', updateWindowWidth)
  }, [])

  useEffect(() => {
    const handleOpen = (): void => {
      if (isUpdate) {
        const cartItem = cart.items[itemIndex]
        setModifiers(cartItem.childItems ?? [])
        setQuantity(Number(cartItem.quantity))
        setNote(cartItem.note ?? '')
      }
      setOpen(true)
    }

    if (menuItem) {
      handleOpen()
    } else {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, menuItem, isUpdate, itemIndex])

  useEffect(() => {
    if (!cart.isCartLoading) {
      setIsOpen(
        (isASAPAvailable && cart.scheduledFor == null) ||
          (isSchedulingAvailable && cart.scheduledFor != null)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue, cart, isASAPAvailable, isSchedulingAvailable])

  useEffect(() => {
    if (menuItem) {
      calculatePrice()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem, quantity])

  const renderModalFooter = (): ReactNode => {
    if (!menuItem) {
      return null
    }

    const renderDisabledItemModal = (label: string): JSX.Element => {
      return (
        <StyledClosedVenueViewCart>
          <StyledClosedButton disabled>{label}</StyledClosedButton>
        </StyledClosedVenueViewCart>
      )
    }

    if (isOTLimitReached) {
      return renderDisabledItemModal(t('ordering_not_available'))
    }

    return !isOpen || menuItem.isOutOfStock ? (
      renderDisabledItemModal(!isOpen ? t('restaurant_closed') : t('out_of_stock'))
    ) : (
      <StyledFooterContent template=".5fr 1fr" align="center">
        <StyledQuantitySelector initialQuantity={quantity} onChange={setQuantity} />
        <StyledFooterButton
          onClick={handleSubmit}
          disabled={!isActive}
          data-test="edit-item-modal-submit"
          isLoading={cart.isCartLoading}
        >
          {t(isUpdate ? 'menu_item.edit_button' : 'menu_item.add_button', {
            value: finalPrice.toFixed(2),
          })}
        </StyledFooterButton>
      </StyledFooterContent>
    )
  }

  const renderModalContent = (): ReactNode => {
    if (!menuItem) {
      return null
    }
    const label = menuItem.label

    return (
      <Grid data-test="edit-item-content">
        <StyledContent p="1rem" gap="16px">
          <Grid template="auto auto" justify="space-between">
            <Text type="H3">{t('currency', { value: menuItem.price })}</Text>
          </Grid>
          <Text type="H2" transform="capitalize">
            {label}
          </Text>
          {menuItem.description && <Text>{menuItem.description}</Text>}
        </StyledContent>
        <Divider classNames={dividerStyles} />
        {menuItem.modifierGroupRefs.map((modifierGroupRef) =>
          modifierGroups[modifierGroupRef.id] != null ? (
            <ModifierGroup
              modifierOptions={operationalMenu.modifierOptions}
              hasError={errors[modifierGroupRef.id] && shouldDisplayErrors}
              key={modifierGroupRef.id}
              modifierGroupRefs={modifierGroupRefs}
              modifierGroup={modifierGroups[modifierGroupRef.id]}
              calculatePrice={calculatePrice}
            />
          ) : null
        )}
        <StyledContent p="1rem" gap="16px">
          <Text type="H3" transform="capitalize">
            {t('menu_item.modifiers.special_instructions.title')}
          </Text>
          <TextField
            label={t('menu_item.modifiers.special_instructions.description')}
            onChange={handleChangeNote}
            value={note}
            name="note"
            maxLength={noteMaxLength}
            onFocus={(input) => {
              const { target } = input
              if (windowWidth != null && windowWidth <= 768) {
                // eslint-disable-next-line no-param-reassign
                target.scrollLeft = input.target.scrollWidth
                // this timeout is ONLY for chrome
                // other browser without timeout works well
                setTimeout(() => {
                  target.setSelectionRange(target.value.length, target.value.length)
                }, 0)
              }
            }}
          />
        </StyledContent>
      </Grid>
    )
  }

  return (
    <StyledModal
      onClose={handleClose}
      open={open}
      data-test="edit-item-modal"
      footer={renderModalFooter()}
      scrollBehavior="SCROLL"
    >
      {renderModalContent()}
    </StyledModal>
  )
}

export default EditItemModal
