import { ComponentProps, ElementType, FunctionComponent } from 'react'
import { cx, css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { SvgIcon, Text } from '@/tbui'

export interface TileOption {
  value: string
  label: string
  subtitle?: Omit<ComponentProps<typeof Text>, 'children'> & { value: string | null }
  onClick?: () => void
  renderTrailingIcon?: JSX.Element
  disabled?: boolean
}

export interface TileSelectProps {
  options: TileOption[]
  name: string
  value: string
  onChange: (option: string) => void
  className?: string
  /** Displays multiple Tiles (row) in a single column */
  stacked?: boolean
  showRadioButton?: boolean
  components?: {
    Tile: ElementType
    Label: ElementType
    Subtitle?: ElementType
  }
  // Disable all tiles, nothing is shown as selected (even if value matches one of the options)
  disabled?: boolean
}

const BaseTile = styled.label`
  border-radius: ${(props): string => props.theme.shape.RADIUS};
  align-content: space-around;
  padding: 0.75rem;
  display: grid;
  border: 2px solid ${({ theme }) => theme.palette.SECONDARY};

  &.is-selected {
    background-color: ${({ theme }) => theme.palette.SECONDARY};
  }
`

const BaseTileLabel = styled(Text)`
  color: ${({ theme }) => theme.palette.TEXT};

  &.is-selected {
    color: ${({ theme }) => theme.palette.TEXT_SECONDARY};
  }
`

const BaseTileSubtitle = styled(Text)`
  color: ${({ theme }) => theme.palette.TEXT};

  &.is-selected,
  &.is-selected * {
    color: ${({ theme }) => theme.palette.TEXT_SECONDARY};
  }
`

const HiddenRadioButton = styled.input`
  display: none;
`

const titleSelectStyles = css`
  display: flex;
`

const radioContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

const BaseIcon = styled.div`
  grid-area: icon;
`

const disableTileStyle = (theme: Theme): string => css`
  border-color: ${theme.palette.GRAY_3};
  border-width: 1px;
  opacity: 0.75;
  cursor: not-allowed;
`

const disableColorStyle = (theme: Theme): string => css`
  color: ${theme.palette.GRAY_4};
`

const TileSelect: FunctionComponent<TileSelectProps> = ({
  options = [],
  showRadioButton,
  onChange,
  value,
  name,
  className,
  stacked,
  components = {
    Tile: BaseTile,
    Label: BaseTileLabel,
    Subtitle: BaseTileSubtitle,
  },
  disabled,
}) => {
  const theme = useTheme()
  const { Tile = BaseTile, Label = BaseTileLabel, Subtitle = BaseTileSubtitle } = components

  return (
    <div className={cx({ [titleSelectStyles]: !stacked }, className)}>
      {options.map((option) => {
        const isSelected = !disabled && option.value === value
        return (
          <Tile
            key={option.value}
            role="button"
            onClick={option.onClick}
            data-test={`${name}-${option.value.toLowerCase()}`}
            className={cx({
              'is-selected': isSelected,
              [disableTileStyle(theme)]: disabled || option.disabled,
            })}
          >
            <HiddenRadioButton
              onChange={(): void => {
                onChange(option.value)
              }}
              value={option.value}
              checked={isSelected}
              name={`${name}-${option.value.toLowerCase()}`}
              id={`${name}-${option.value.toLowerCase()}`}
              type="radio"
              disabled={disabled || option.disabled}
            />
            <div className={radioContainerStyles}>
              {showRadioButton && (
                <SvgIcon
                  name={isSelected ? 'filled_circle' : 'circle'}
                  fontSize="22px"
                  color={isSelected ? 'TEXT_SECONDARY' : 'GRAY_3'}
                />
              )}
              <Label
                align="center"
                className={cx({
                  'is-selected': isSelected,
                  [disableColorStyle(theme)]: disabled || option.disabled,
                })}
                bold={isSelected}
                data-test={`${name}-${option.value}-label`}
              >
                {option.label}
              </Label>
            </div>
            {option.subtitle && (
              <Subtitle
                data-test={`${name}-${option.value}-subtitle`}
                className={cx({
                  'is-selected': isSelected,
                  [disableColorStyle(theme)]: disabled || option.disabled,
                })}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...option.subtitle}
              >
                {option.subtitle.value}
              </Subtitle>
            )}
            {option.renderTrailingIcon != null && (
              <BaseIcon>{isSelected && option.renderTrailingIcon}</BaseIcon>
            )}
          </Tile>
        )
      })}
    </div>
  )
}

export default TileSelect
