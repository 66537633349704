import { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import Image from 'next/image'
import { Venue } from '@/libs/helpers/adapters'
import { Grid, Text } from '@/tbui'

interface GuestCheckoutHeaderProps {
  name: Venue['name']
  branding: {
    logoUrl: Venue['branding']['logoUrl']
  }
}

const StyledWrapper = styled(Grid)<{ hasLogoUrl?: boolean }>`
  grid-column-gap: 20px;
  grid-template-areas: ${(props) =>
    props.hasLogoUrl ? "'logo info info info info'" : "'info info info info info'"};
  grid-template-columns: fit-content(100px) 1fr;
  align-items: center;
`

const StyledLogo = styled(Grid)`
  grid-area: logo;
  max-width: 100px;
  min-width: 75px;
  border: 1px solid #ccc;
  border-radius: 8px;
  ${(props) =>
    props.theme.mixin.fluidRange({
      prop: 'width',
      fromSize: '75px',
      toSize: '100px',
    })};
`

const StyledName = styled(Text)`
  grid-area: info;
`

const GuestCheckoutHeader: FunctionComponent<GuestCheckoutHeaderProps> = ({
  name,
  branding: { logoUrl },
}) => {
  return (
    <StyledWrapper hasLogoUrl={!!logoUrl}>
      {logoUrl && (
        <StyledLogo data-test="guest-checkout-branding-logo">
          <Image
            src={logoUrl}
            width="100px"
            height="100px"
            layout="responsive"
            priority
            objectFit="cover"
            unoptimized
            style={{ borderRadius: '8px' }}
            alt=""
          />
        </StyledLogo>
      )}
      <StyledName type="H1" data-test="guest-checkout-restaurant-name">
        {name}
      </StyledName>
    </StyledWrapper>
  )
}

export default GuestCheckoutHeader
