import type { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { CircularLoader, Grid, Text } from '@/tbui'
import { CartWidgetVariant } from './CartItem'

const StyledCartHeader = styled(Grid)`
  padding: 24px;
  ${(props) =>
    props.theme.mixin.fluidRange(
      {
        prop: 'paddingTop',
        fromSize: '20px',
        toSize: '40px',
      },
      {
        prop: 'paddingBottom',
        fromSize: '20px',
        toSize: '40px',
      }
    )};
  @media (max-width: ${(props) => props.theme.breakpoints.SM}) {
    padding: 0 24px;
  }
`

const EmptyCart: FunctionComponent<{
  variant: CartWidgetVariant
  isCartLoading: boolean
}> = ({ variant, isCartLoading }) => {
  const { t } = useTranslation()

  const cartContext = variant.toLowerCase()

  return (
    <Grid p="50% 24px" data-test="empty-cart-body">
      <StyledCartHeader gap="24px" justify="center">
        {isCartLoading ? (
          <CircularLoader fontSize="60px" />
        ) : (
          <>
            <Text type="H2" align="center">
              {t('cart_widget.header.title', {
                context: cartContext,
              })}
            </Text>
            <Text align="center" type="H3" bold={false}>
              {t('cart_widget.empty', {
                context: cartContext,
              })}
            </Text>
          </>
        )}
      </StyledCartHeader>
    </Grid>
  )
}

export default EmptyCart
