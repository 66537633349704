import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import GuestCheckoutFooter from '@/components/GuestCheckout/GuestCheckoutFooter'
import { GuestInformation } from '@/libs/helpers/apiClient'
import useCookies from '@/libs/helpers/hooks/useCookies'
import { OrderMethod } from '@/libs/helpers/utils'
import { Button, Grid, Text } from '@/tbui'

interface IFinishAccountModalProps {
  continueAsGuest: () => void
  guest: GuestInformation
  venueXRefID: string
  orderMethod: OrderMethod
}

const FinishAccountModal: FunctionComponent<IFinishAccountModalProps> = ({
  continueAsGuest,
  guest,
  venueXRefID,
  orderMethod,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { setRedirectURLCookie } = useCookies()
  const checkoutPath = `/${orderMethod.toLowerCase()}/${venueXRefID}/checkout`

  const finishCreatingAccount = async (): Promise<void> => {
    setRedirectURLCookie({
      url: '/[orderMethod]/[venueXRefID]/checkout',
      as: checkoutPath,
    })
    const queryParams = {
      p: 'B2C_1A_signup_signin',
      signup_signin_option: 'signup',
      signup_firstname: guest.firstName,
      signup_lastname: guest.lastName,
      signup_phone: guest.phone,
      signup_email: guest.email,
    }
    router.push(
      `/api/consumer-frontend/auth/azure-b2c/login?${new URLSearchParams(queryParams).toString()}`
    )
  }

  return (
    <Grid gap="1rem" data-test="finish-account-container">
      <Text type="H4" align="center" bold>
        {t('guest_checkout.email_used_before')}
      </Text>
      <Text type="H4" align="center" bold m="0 0 0.75rem 0">
        {t('guest_checkout.finish_account_creation')}
      </Text>
      <Button
        variant="PRIMARY"
        onClick={() => finishCreatingAccount()}
        type="button"
        data-test="guest-checkout-finish-creating-btn"
      >
        {t('guest_checkout.finish_creating_account')}
      </Button>
      <Button
        variant="OUTLINE"
        onClick={() => continueAsGuest()}
        type="button"
        data-test="continue-as-guest-btn"
      >
        {t('guest_checkout.continue_as_guest')}
      </Button>
      <GuestCheckoutFooter />
    </Grid>
  )
}

export default FinishAccountModal
