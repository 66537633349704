import { FunctionComponent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Big from 'big.js'
import { useTranslation } from 'react-i18next'
import type { OnlineOperationalModifierOption } from '@/libs/helpers/adapters'
import type { CartChildItem, CartItem } from '@/libs/helpers/utils'
import { CheckBox, Grid, QuantitySelector, Text } from '@/tbui'
import { useMenuItem } from './context'

interface ModifierOptionProps {
  // Used to differentiate which modifier option is selected if there are
  // multiple modifier groups
  selectionId: string
  modifierOption: OnlineOperationalModifierOption
  isDisabled: boolean
  checkModifiers: () => void
  selectionMax: number
  // API expects modifierGroupXRefID but we use "entityID" here for consistency
  modifierGroupID: string
}

const StyledQuantitySelector = styled(QuantitySelector)`
  justify-content: center;
  @media (max-width: ${(props) => props.theme.breakpoints.XS}) {
    gap: 8px;
  }
  i {
    font-size: 30px;
    height: 30px;
    width: 30px;
  }
  p {
    font-size: 16px;
    height: 18px;
  }
  gap: 10px;
`

const StyledCheckbox = styled(CheckBox)`
  i {
    display: block;
  }
`
const StyledGrid = styled(Grid)`
  padding: 12px 0;
  &:not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.palette.GRAY_1};
  }
`

const ModifierOption: FunctionComponent<ModifierOptionProps> = ({
  selectionId,
  modifierOption,
  isDisabled,
  checkModifiers,
  selectionMax,
  modifierGroupID,
}) => {
  const [quantity, setQuantity] = useState<number>(0)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const { t } = useTranslation()

  const { modifiers, setModifiers } = useMenuItem()
  const price = modifierOption.modifierGroupRefs[modifierGroupID]?.price ?? modifierOption.price

  const addModifier = (newQuantity: number): CartChildItem[] => {
    return [
      ...(modifiers ?? []),
      {
        price,
        quantity: newQuantity.toFixed(2),
        itemID: modifierOption.XRefID,
        modifierGroupXRefID: modifierGroupID,
        selectionId,
      },
    ]
  }

  const removeModifier = (): CartItem['childItems'] => {
    if (modifiers != null) {
      const updatedModifiers = modifiers.filter((modifier) => modifier.selectionId !== selectionId)
      return updatedModifiers
    }
    return modifiers
  }

  const updateModifier = (selectedModifier: CartChildItem): CartChildItem[] | undefined => {
    if (modifiers != null) {
      const modifierIndex = modifiers.findIndex(
        (modifier) => modifier.selectionId === selectedModifier.selectionId
      )
      modifiers[modifierIndex] = selectedModifier
    }

    return modifiers
  }

  const handleMaxModChange = (newQuantity: number): void => {
    const selectedModifier = modifiers?.find((modifier) => modifier.selectionId === selectionId)
    if (selectedModifier != null) {
      if (newQuantity > 0) {
        selectedModifier.quantity = newQuantity.toString()
        if (selectedModifier.price != null) {
          selectedModifier.price = Big(newQuantity)
            .mul(Big(selectedModifier.price).div(quantity))
            .toFixed(2)
        }

        setModifiers(updateModifier(selectedModifier))
      } else {
        setModifiers(removeModifier())
      }
    } else if (newQuantity > 0) {
      setModifiers(addModifier(newQuantity))
    }
    setQuantity(newQuantity)
    // It is to set state to false without it isSelected is true should be false
    if (newQuantity === 0) {
      setIsSelected(false)
    }
  }

  useEffect(() => {
    checkModifiers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, isSelected])

  useEffect(() => {
    if (modifiers != null && modifiers.length > 0) {
      const modifierToUpdate = modifiers.find((modifier) => modifier.selectionId === selectionId)
      if (modifierToUpdate != null && modifierToUpdate.modifierGroupXRefID == null) {
        // If modifier doesn't have modifierGroupXRefID then we add it using the prop
        // passed into this component
        modifierToUpdate.modifierGroupXRefID = modifierGroupID

        setModifiers(updateModifier(modifierToUpdate))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers])

  useEffect(() => {
    if (modifiers) {
      const modifier = modifiers.find((mod) => mod.selectionId === selectionId)
      if (modifier) {
        setQuantity(Number(modifier.quantity))
      }
    }
  }, [modifierOption.XRefID, modifiers, selectionId])

  useEffect(() => {
    if (isSelected) {
      handleMaxModChange(1)
    } else {
      handleMaxModChange(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected])

  const renderInput =
    quantity > 0 ? (
      <StyledQuantitySelector
        quantityMin={0}
        quantityMax={selectionMax === 0 ? 99 : selectionMax}
        initialQuantity={quantity}
        onChange={(newQuantity) => handleMaxModChange(newQuantity)}
        disableAdd={isDisabled || modifierOption.isOutOfStock}
      />
    ) : (
      <StyledCheckbox
        onChange={(e) => {
          setIsSelected(e.target.checked)
        }}
        name={selectionId}
        id={selectionId}
        variant="ADD"
        isDisabled={isDisabled || modifierOption.isOutOfStock}
        iconSize="30px"
      />
    )

  return (
    <StyledGrid template="1fr auto" gap="6px" align="end">
      <Grid
        template="auto 1fr"
        gap="16px"
        align="center"
        data-test={`modifier-option-container-${selectionId}`}
      >
        {renderInput}
        <Grid gap="6px">
          <Text
            type="H4"
            transform="capitalize"
            style={modifierOption.isOutOfStock ? { textDecoration: 'line-through' } : {}}
            data-test={`modifier-option-${selectionId}-label`}
          >
            {modifierOption.label}
          </Text>
        </Grid>
      </Grid>
      <Text color="TEXT_2" data-test={`modifier-option-${selectionId}-price`}>
        {t('currency', { value: price })}
      </Text>
    </StyledGrid>
  )
}

export default ModifierOption
