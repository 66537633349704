import { FunctionComponent, useState } from 'react'
import { useRouter } from 'next/router'
import GuestCheckoutFooter from '@/components/GuestCheckout/GuestCheckoutFooter'
import GuestCheckoutHeader from '@/components/GuestCheckout/GuestCheckoutHeader'
import GuestInfoSection, { GuestInfoFormFields } from '@/components/GuestCheckout/GuestInfoSection'
import { Venue } from '@/libs/helpers/adapters'
import { GuestInformation, checkUser } from '@/libs/helpers/apiClient'
import { OrderMethod } from '@/libs/helpers/utils'
import { useModal } from '@/providers/modal'
import { Grid } from '@/tbui'
import ExistingAccountModal from './ExistingAccountModal'
import FinishAccountModal from './FinishAccountModal'

interface CheckoutAsGuestModalProps {
  name: Venue['name']
  branding: {
    logoUrl: Venue['branding']['logoUrl']
  }
  handleUpdateMe: (guest: GuestInformation) => Promise<void>
  venueXRefID: string
  orderMethod: OrderMethod
}

export const mapFormFieldToGuest = (formFields: GuestInfoFormFields): GuestInformation => ({
  email: formFields['email'].value.toString(),
  firstName: formFields['firstName'].value.toString(),
  lastName: formFields['lastName'].value.toString(),
  phone: formFields['phone'].value.toString().replace(/\D/g, ''),
  smsConsent: formFields['smsConsent'].value as boolean,
})

const CheckoutAsGuestModal: FunctionComponent<CheckoutAsGuestModalProps> = ({
  name,
  branding,
  handleUpdateMe,
  venueXRefID,
  orderMethod,
}) => {
  const router = useRouter()
  const { closeModal, openModal } = useModal()
  const [isLoading, setLoading] = useState(false)
  const checkoutPath = `/${orderMethod.toLowerCase()}/${venueXRefID}/checkout`

  const continueAsGuest = async (guest: GuestInformation): Promise<void> => {
    if (venueXRefID) {
      closeModal()
      await handleUpdateMe(guest)
      await router.push('/[orderMethod]/[venueXRefID]/checkout', checkoutPath)
    }
  }

  const onSubmit = async (validatedFormFields: GuestInfoFormFields): Promise<void> => {
    const guest = mapFormFieldToGuest(validatedFormFields)

    let checkUserResponse = { tableupAccountExists: false, tbdineAccountExists: false }
    try {
      setLoading(true)
      checkUserResponse = await checkUser({
        email: guest.email,
        phone: guest.phone,
      })
      setLoading(false)
    } catch (e) {
      // If any error occurs, force a guest checkout
      await continueAsGuest(guest)
      return
    }

    if (checkUserResponse.tbdineAccountExists) {
      closeModal()
      openModal(
        <ExistingAccountModal
          continueAsGuest={() => continueAsGuest(guest)}
          guest={guest}
          orderMethod={orderMethod}
          venueXRefID={venueXRefID}
        />,
        {
          onClose: () => closeModal(),
          scrollBehavior: 'SCROLL',
        }
      )
      return
    } else if (checkUserResponse.tableupAccountExists) {
      closeModal()
      openModal(
        <FinishAccountModal
          continueAsGuest={() => continueAsGuest(guest)}
          guest={guest}
          orderMethod={orderMethod}
          venueXRefID={venueXRefID}
        />,
        {
          onClose: () => closeModal(),
          scrollBehavior: 'SCROLL',
        }
      )
      return
    }

    await continueAsGuest(guest)
  }

  return (
    <Grid gap="1.75rem" data-test="checkout-as-guest-container">
      <GuestCheckoutHeader name={name} branding={branding} />
      <GuestInfoSection
        submit={{ callback: onSubmit }}
        isLoading={isLoading}
        orderMethod={orderMethod}
        venueXRefID={venueXRefID}
      />
      <GuestCheckoutFooter />
    </Grid>
  )
}

export default CheckoutAsGuestModal
