import { FunctionComponent, ReactNode } from 'react'
import { css, cx } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useMenuItem } from '@/components/EditItemModal/context'
import PulsateLoader from '@/components/PulsateLoader'
import type { OperationalMenu } from '@/libs/helpers/adapters'
import { OrderItem, calcLineItemCost } from '@/libs/helpers/utils'
import { SvgIcon, Text } from '@/tbui'

const QUANTITY_MAX_ITEMS = 99

export enum CartWidgetVariant {
  CHECKOUT = 'CHECKOUT',
  MENU = 'MENU',
}

const cartItemStyles = css`
  display: grid;
  gap: 1rem;
`

const cartTotalStyles = css`
  display: grid;
  grid-template-columns: auto 1fr;
`

const cartItemTile = (theme: Theme): string => css`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-content: center;
  align-items: center;
  gap: 20px;
  border-radius: ${theme.shape.RADIUS};
  background: ${theme.palette.WHITE};
  word-break: break-word;

  &.is-menu {
    grid-template-columns: 1fr auto;
  }
`

const counterStyles = css`
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-content: center;
  align-items: center;
  gap: 8px;
`

const itemNoteStyles = css`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`

const quantityStyles = (theme: Theme): string => css`
  display: grid;
  border-radius: ${theme.shape.RADIUS};
  background: ${theme.palette.SECONDARY};
  padding: 6px 16px;
`

const StyledButton = styled.button`
  font-family: ${(props) => props.theme.font.FAMILY_BOLD};
  background: ${(props) => props.theme.palette.WHITE};
  color: ${(props) => props.theme.palette.PRIMARY};
  place-content: center;
  border-radius: 6px;
  font-size: 14px;
  display: grid;
  height: 44px;
  border: none;
  width: 44px;
  background: ${(props) => props.theme.palette.SECONDARY};
  :disabled {
    filter: grayscale(100%);
    pointer-events: none;
  }
`

const StyledQuantityText = styled(Text)`
  width: 20px;
  text-align: center;
`

const StyledNotes = styled(Text)`
  word-break: break-all;
`

const StyledModifierWrapper = styled.div`
  display: grid;
  gap: 10px;
`

const modifierStyles = css`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
`

const modifierTextStyles = css`
  display: flex;
  gap: 6px;
`

const StylePrice = styled(Text)`
  word-break: normal;
`

interface CartItemProps {
  variant: CartWidgetVariant
  item: OrderItem
  index: number
  isCartLoading: boolean
  operationalMenu: OperationalMenu
  disableCounters: boolean
  onDecrementItem: (item: OrderItem, index: number) => Promise<void>
  onIncrementItem: (item: OrderItem, index: number) => Promise<void>
}

const CartItem: FunctionComponent<CartItemProps> = ({
  variant,
  item,
  index,
  isCartLoading,
  operationalMenu,
  disableCounters,
  onIncrementItem,
  onDecrementItem,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { setMenuItem, setItemIndex } = useMenuItem()

  const menuItems = operationalMenu.menuItems

  const { name: itemName, childItems, quantity, note, itemID, menuGroupXRefID } = item
  const quantityAsNumber = Number(quantity)

  const renderCounters: ReactNode = (
    <div className={cartTotalStyles}>
      <div className={counterStyles}>
        <StyledButton
          data-test={`cart-item-${item.itemID}-${index}-minus`}
          onClick={() => onDecrementItem(item, index)}
          disabled={isCartLoading}
        >
          <SvgIcon name="remove" color="TEXT_SECONDARY" fontSize="36px" />
        </StyledButton>
        <StyledQuantityText data-test={`cart-item-${item.itemID}-${index}-quantity`} bold>
          {quantityAsNumber}
        </StyledQuantityText>
        <StyledButton
          disabled={quantityAsNumber === QUANTITY_MAX_ITEMS || isCartLoading}
          onClick={() => onIncrementItem(item, index)}
          data-test={`cart-item-${item.itemID}-${index}-plus`}
        >
          <SvgIcon name="add" color="TEXT_SECONDARY" fontSize="30px" />
        </StyledButton>
      </div>
      <div css={{ justifySelf: 'end', alignSelf: 'center' }}>
        <StylePrice
          type="H4"
          transform="capitalize"
          data-test={`cart-item-${item.itemID}-${index}-total-price`}
        >
          {t('currency', {
            value: calcLineItemCost(item),
          })}
        </StylePrice>
      </div>
    </div>
  )

  const handleItemClick = (): void => {
    const menuItem = menuItems[itemID]
    setItemIndex(index)
    setMenuItem({ ...menuItem, menuGroupXRefID })
  }

  const renderModifiers = ({
    modifierID,
    modifierName,
    modifierQuantity,
    modifierPrice,
    modifierGroupXRefID,
  }: {
    modifierID: string
    modifierName: string
    modifierQuantity: string
    modifierPrice: string
    modifierGroupXRefID: string
  }): ReactNode => {
    return (
      <div
        className={modifierStyles}
        data-test={`cart-item-modifier-${modifierGroupXRefID}_${modifierID}-${index}`}
        key={`${modifierGroupXRefID}_${modifierID}`}
      >
        <div className={modifierTextStyles}>
          <Text transform="capitalize">{`+ ${modifierName}`}</Text>
          {modifierQuantity !== '1.00' && (
            <Text> {`(x${Math.floor(Number(modifierQuantity))})`}</Text>
          )}
        </div>
        {isCartLoading ? (
          <PulsateLoader />
        ) : (
          <Text transform="capitalize" align="right">
            {`(${t('currency', {
              value: modifierPrice,
            })})`}
          </Text>
        )}
      </div>
    )
  }

  return (
    <div data-test={`cart-item-${item.itemID}-${index}`} className={cartItemStyles}>
      <div css={{ display: 'grid', gap: '0.5rem' }}>
        <div
          className={cx(cartItemTile(theme), {
            [`is-${CartWidgetVariant.MENU.toLocaleLowerCase()}`]:
              variant === CartWidgetVariant.MENU,
          })}
        >
          {disableCounters && (
            // only rendered in checkout
            <div className={quantityStyles(theme)}>
              <Text
                color="TEXT_SECONDARY"
                bold
                data-test={`cart-item-${item.itemID}-${index}-tile-quantity`}
              >
                {quantityAsNumber}
              </Text>
            </div>
          )}
          <Text
            onClick={handleItemClick}
            transform="capitalize"
            type="H4"
            data-test={`cart-item-${item.itemID}-${index}-name`}
          >
            {itemName}
          </Text>
          {isCartLoading ? (
            <PulsateLoader height="16px" />
          ) : (
            <StylePrice
              type="H4"
              bold={false}
              transform="capitalize"
              data-test={`cart-item-${item.itemID}-${index}-price`}
            >
              {t('currency', {
                value: item.price,
              })}
            </StylePrice>
          )}
        </div>
        {((childItems && childItems.length > 0) || note) && (
          <StyledModifierWrapper>
            {(childItems ?? []).map(
              ({
                itemID: modifierID,
                price: modifierPrice,
                name: modifierName,
                quantity: modifierQuantity,
                modifierGroupXRefID,
              }) =>
                renderModifiers({
                  modifierID,
                  modifierPrice,
                  modifierName,
                  modifierQuantity,
                  modifierGroupXRefID,
                })
            )}
            {note && (
              <div data-test={`cart-item-${item.itemID}-${index}-notes`} className={itemNoteStyles}>
                <StyledNotes transform="capitalize">
                  + {t('cart_widget.item.notes', { value: note })}
                </StyledNotes>
              </div>
            )}
          </StyledModifierWrapper>
        )}
      </div>

      {!disableCounters && renderCounters}
    </div>
  )
}

export default CartItem
