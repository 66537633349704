import type { CSSProperties, FunctionComponent } from 'react'
import styled from '@emotion/styled'

const StyledLoader = styled.div<PulsateLoaderProps>`
  @keyframes skeleton-loading {
    0% {
      background-color: ${(props) => props.theme.palette.GRAY_2};
    }
    100% {
      background-color: ${(props) => props.theme.palette.SECONDARY};
    }
  }

  animation: skeleton-loading 1.5s linear infinite alternate;
  ${(props) => props.width && { width: `${props.width}` }};
  ${(props) => props.height && { height: `${props.height}` }};
  padding: 0.25rem 0.25rem;
  border-radius: 0.25rem;
`

interface PulsateLoaderProps {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}

const PulsateLoader: FunctionComponent<PulsateLoaderProps> = ({
  width = '100%',
  // the base size of the font
  height = '14px',
}) => {
  return <StyledLoader width={width} height={height} />
}

export default PulsateLoader
