import { ChangeEvent, FormEvent, FunctionComponent, useMemo, useState } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'react-i18next'
import { Checkbox, FieldTypes, TextInput } from '@/libs/helpers/adapters'
import { GuestInformation } from '@/libs/helpers/apiClient'
import { formatPhone } from '@/libs/helpers/formatters'
import useCookies from '@/libs/helpers/hooks/useCookies'
import { OrderMethod } from '@/libs/helpers/utils'
import { validateEmail, validatePhoneNumber } from '@/libs/helpers/validators'
import { Button, CheckBox, Grid, InputVariant, Text, TextField, formStyles } from '@/tbui'

const maxLength = 100

const linkFont = (theme: Theme): string => css`
  font-family: ${theme.font.FAMILY_BOLD};
  color: ${theme.palette.PRIMARY};
  text-decoration: none;
`
const StyledErrorText = styled(Text)`
  color: ${(props) => props.theme.palette.ERROR};
  margin-top: 10px;
`

interface GuestInfoSectionProps {
  title?: string
  guest?: GuestInformation | null
  submit: {
    text?: string
    showAgreement?: boolean
    callback: (validatedFormFields: GuestInfoFormFields) => void
  }
  isLoading: boolean
  venueXRefID: string
  orderMethod: OrderMethod
}

export interface GuestInfoFormFields {
  firstName: TextInput
  lastName: TextInput
  email: TextInput
  phone: TextInput
  smsConsent: Checkbox
}

type InvalidFields = {
  [key in keyof GuestInfoFormFields]: boolean
}

const defaultInvalidFields: InvalidFields = {
  firstName: false,
  lastName: false,
  email: false,
  phone: false,
  smsConsent: false,
}

const GuestInfoSection: FunctionComponent<GuestInfoSectionProps> = ({
  title,
  guest,
  submit: { text, showAgreement = true, callback },
  isLoading,
  venueXRefID,
  orderMethod,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const router = useRouter()
  const { setRedirectURLCookie } = useCookies()
  const checkoutPath = `/${orderMethod.toLowerCase()}/${venueXRefID}/checkout`

  // FORM DEFAULTS
  const initialForm: GuestInfoFormFields = {
    firstName: {
      value: guest?.firstName ?? '',
      validate: (firstName: string): boolean => !!firstName.trim(),
      type: FieldTypes.TEXT_INPUT,
    },
    lastName: {
      value: guest?.lastName ?? '',
      validate: (lastName: string): boolean => !!lastName.trim(),
      type: FieldTypes.TEXT_INPUT,
    },
    email: {
      value: guest?.email ?? '',
      validate: validateEmail,
      type: FieldTypes.TEXT_INPUT,
    },
    phone: {
      value: formatPhone(guest?.phone ?? null) ?? '',
      validate: validatePhoneNumber,
      type: FieldTypes.TEXT_INPUT,
    },
    smsConsent: {
      value: guest?.smsConsent ?? false,
      type: FieldTypes.CHECKBOX,
    },
  }

  const [formFields, setFormFields] = useState<GuestInfoFormFields>(initialForm)
  const [invalidFields, setInvalidFields] = useState<InvalidFields>(defaultInvalidFields)
  const validateFormFields = (): boolean => {
    const tempInvalidFields: InvalidFields = { ...defaultInvalidFields }
    Object.keys(formFields).forEach((fieldName: string) => {
      // lookup field value and validator by name
      const field = formFields[fieldName as keyof GuestInfoFormFields]
      // keep track of invalid fields
      if (field.validate && !field.validate(field.value as never)) {
        tempInvalidFields[fieldName as keyof GuestInfoFormFields] = true
      }
    })

    setInvalidFields(tempInvalidFields)
    return Object.values(tempInvalidFields).every((invalidField) => !invalidField)
  }

  const shouldContinueBtnDisabled = useMemo((): boolean => {
    return (
      Object.values(formFields).some((field) => {
        if (!field.validate) {
          return false
        }
        return !field.value
      }) || !Object.values(invalidFields).every((invalidField) => !invalidField)
    )
  }, [formFields, invalidFields])

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (!validateFormFields()) {
      return
    }
    callback(formFields)
  }

  const onChangeField = (name: string, e: ChangeEvent<HTMLInputElement>): void => {
    if (invalidFields[name as keyof GuestInfoFormFields]) {
      setInvalidFields({ ...invalidFields, [name]: false })
    }
    const changingField = formFields[name as keyof GuestInfoFormFields]
    const textInputValue = name === 'phone' ? formatPhone(e.target.value) : e.target.value
    setFormFields({
      ...formFields,
      [name]: {
        ...changingField,
        value: changingField.type === FieldTypes.CHECKBOX ? e.target.checked : textInputValue,
      },
    })
  }

  const onPhoneFieldBlur = (): void => {
    const isValidNum = validatePhoneNumber(formFields['phone'].value)
    if (isValidNum) {
      setInvalidFields({ ...invalidFields, ['phone']: false })
    } else {
      setInvalidFields({ ...invalidFields, ['phone']: true })
    }
  }

  const signIn = async (): Promise<void> => {
    setRedirectURLCookie({
      url: '/[orderMethod]/[venueXRefID]/checkout',
      as: checkoutPath,
    })
    const queryParams = {
      p: 'B2C_1A_signup_signin',
      login_hint: formFields.email.value as string,
    }
    router.push(
      `/api/consumer-frontend/auth/azure-b2c/login?${new URLSearchParams(queryParams).toString()}`
    )
  }

  const signUp = async (): Promise<void> => {
    setRedirectURLCookie({
      url: '/[orderMethod]/[venueXRefID]/checkout',
      as: checkoutPath,
    })
    const queryParams = {
      p: 'B2C_1A_signup_signin',
      signup_signin_option: 'signup',
      signup_firstname: formFields.firstName.value as string,
      signup_lastname: formFields.lastName.value as string,
      signup_phone: formFields.phone.value as string,
      signup_email: formFields.email.value as string,
    }
    router.push(
      `/api/consumer-frontend/auth/azure-b2c/login?${new URLSearchParams(queryParams).toString()}`
    )
  }

  return (
    <>
      <Text type="H2" data-test="guest-info-section-title">
        {title ?? t('guest_checkout.checkout_as_guest')}
      </Text>
      <form onSubmit={(e) => onSubmit(e)}>
        <Grid container gap="1.75rem">
          <Grid>
            <label
              css={[formStyles(theme).label]}
              data-test="guest-first-name-label"
              htmlFor="guest-first-name"
            >
              {t('guest_checkout.first_name')}
            </label>
            <TextField
              name="firstName"
              variant={InputVariant.INPUT}
              onChange={(e) => onChangeField('firstName', e)}
              data-test="guest-first-name"
              id="guest-first-name"
              value={formFields.firstName.value as string}
              maxLength={maxLength}
              placeholder={t('guest_checkout.first_name_placeholder')}
            />
            {invalidFields['firstName'] && (
              <StyledErrorText type="P" data-test="guest-first-name-error">
                {t('guest_checkout.error_message_first_name')}
              </StyledErrorText>
            )}
          </Grid>
          <Grid>
            <label
              css={[formStyles(theme).label]}
              data-test="guest-last-name-label"
              htmlFor="guest-last-name"
            >
              {t('guest_checkout.last_name')}
            </label>
            <TextField
              name="lastName"
              variant={InputVariant.INPUT}
              onChange={(e) => onChangeField('lastName', e)}
              data-test="guest-last-name"
              id="guest-last-name"
              value={formFields.lastName.value as string}
              maxLength={maxLength}
              placeholder={t('guest_checkout.last_name_placeholder')}
            />
            {invalidFields['lastName'] && (
              <StyledErrorText type="P" data-test="guest-last-name-error">
                {t('guest_checkout.error_message_last_name')}
              </StyledErrorText>
            )}
          </Grid>
          <Grid container gap="0.5rem">
            <Grid>
              <label
                css={[formStyles(theme).label]}
                data-test="guest-email-label"
                htmlFor="guest-email"
              >
                {t('guest_checkout.email')}
              </label>
              <TextField
                name="email"
                variant={InputVariant.INPUT}
                onChange={(e) => onChangeField('email', e)}
                data-test="guest-email"
                id="guest-email"
                value={formFields.email.value as string}
                placeholder={t('guest_checkout.email_placeholder')}
              />
              {invalidFields['email'] && (
                <StyledErrorText type="P" data-test="guest-email-error">
                  {t('guest_checkout.error_message_email')}
                </StyledErrorText>
              )}
            </Grid>
            <Text type="P" color="TEXT_2" data-test="guest-email-tip">
              {t('guest_checkout.email_tip')}
            </Text>
          </Grid>
          <Grid container gap="0.5rem">
            <Grid>
              <label
                css={[formStyles(theme).label]}
                data-test="guest-phone-label"
                htmlFor="guest-phone"
              >
                {t('guest_checkout.phone_number')}
              </label>
              <TextField
                name="phone"
                variant={InputVariant.INPUT}
                onChange={(e) => onChangeField('phone', e)}
                onBlur={onPhoneFieldBlur}
                data-test="guest-phone"
                id="guest-phone"
                value={formFields.phone.value as string}
                placeholder={t('guest_checkout.phone_number_placeholder')}
              />
              {invalidFields['phone'] && (
                <StyledErrorText type="P" data-test="guest-phone-error">
                  {t('guest_checkout.error_message_phone_number')}
                </StyledErrorText>
              )}
            </Grid>
            <Grid container style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
              <CheckBox
                iconSize="24px"
                id="guest-receive-via-sms"
                data-test="guest-receive-via-sms"
                name="smsConsent"
                variant="SQUARE"
                onChange={(e) => onChangeField('smsConsent', e)}
                isSelected={formFields.smsConsent.value as boolean}
              />
              <Text type="P" color="TEXT_2" data-test="guest-smsConsent-text">
                <label htmlFor="guest-receive-via-sms">
                  {t('checkout.contact.sms_notification')}
                </label>
              </Text>
            </Grid>
          </Grid>
          {showAgreement && (
            <Text type="P" color="TEXT" data-test="guest-policy">
              <Trans
                i18nKey="guest_checkout.agreement"
                components={{
                  1: (
                    <a
                      className={linkFont(theme)}
                      target="_blank"
                      href={t('tbdine.footer.right.link_terms.href')}
                      rel="noreferrer"
                    />
                  ),
                  2: (
                    <a
                      className={linkFont(theme)}
                      target="_blank"
                      href={t('tbdine.footer.right.link_privacy.href')}
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </Text>
          )}
          <Button
            variant="PRIMARY"
            data-test="continue-as-guest-btn"
            type="submit"
            disabled={shouldContinueBtnDisabled || isLoading}
          >
            {text ?? t('guest_checkout.continue_as_guest')}
          </Button>
        </Grid>
      </form>
      {showAgreement && (
        <>
          <Button
            variant="LINK"
            onClick={() => signIn()}
            type="button"
            data-test="sign-in-btn"
            disabled={isLoading}
          >
            {t('tbdine.sign_in')}
          </Button>
          <Button
            variant="LINK"
            onClick={() => signUp()}
            type="button"
            data-test="create-account-btn"
            disabled={isLoading}
          >
            {t('tbdine.create_account')}
          </Button>
        </>
      )}
    </>
  )
}

export default GuestInfoSection
