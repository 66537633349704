import { FunctionComponent, useEffect, useState } from 'react'
import type { LoyaltySessionState } from '@/components/Checkout'
import type { EvaluateLoyaltyRewards, Loyalty, VenueLoyalty } from '@/libs/helpers/apiClient'
import ApplyLoyaltyRewardButton from './ApplyLoyaltyRewardButton'

interface LoyaltyContainerProps {
  userRewards: Loyalty | null
  loyaltySession: LoyaltySessionState
  onSelectReward: (selectedReward: string | null) => Promise<void>
  loyaltyConfig: VenueLoyalty
}

const LoyaltyContainer: FunctionComponent<LoyaltyContainerProps> = ({
  loyaltySession,
  onSelectReward,
  userRewards,
  loyaltyConfig,
}) => {
  // INITIAL STATE
  const LOYALTY_TRANSACTION_INITIAL_STATE: EvaluateLoyaltyRewards = {
    loyalty: userRewards,
    selectedRewardXRefID: loyaltySession.selectedRewardXRefID ?? null,
  }

  const [loyaltyTransaction, setLoyaltyTransaction] = useState<EvaluateLoyaltyRewards>(
    LOYALTY_TRANSACTION_INITIAL_STATE
  )

  useEffect(() => {
    if (userRewards != null) {
      setLoyaltyTransaction((fields) => ({
        ...fields,
        loyalty: userRewards,
      }))
    }
  }, [userRewards])

  return (
    <ApplyLoyaltyRewardButton
      loyaltyTransaction={loyaltyTransaction}
      venueRewards={loyaltyConfig?.config?.rewards ?? []}
      loyaltySession={loyaltySession}
      onSelectReward={async (selectedReward: string | null): Promise<void> => {
        await onSelectReward(selectedReward)
        setLoyaltyTransaction((fields: EvaluateLoyaltyRewards) => ({
          ...fields,
          selectedRewardXRefID: selectedReward,
        }))
      }}
    />
  )
}

export default LoyaltyContainer
