import {
  FunctionComponent,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  Dispatch,
  useEffect,
  ReactNode,
} from 'react'
import type {
  OnlineOperationalMenuItem,
  OnlineOperationalMenuRefItem,
  OperationalMenu,
} from '@/libs/helpers/adapters'
import type { CartItem } from '@/libs/helpers/utils'

interface ModifierValidationErrors {
  [name: string]: boolean
}

export interface ContextType {
  setModifiers: Dispatch<CartItem['childItems']>
  setErrors: Dispatch<ModifierValidationErrors>
  modifiers: CartItem['childItems']
  setMenuItem: Dispatch<OnlineOperationalMenuItemCtxPayload | null>
  setItemIndex: Dispatch<number>
  errors: ModifierValidationErrors
  menuItem: OnlineOperationalMenuItemCtxPayload | null
  itemIndex: number
  shouldDisplayErrors: boolean
  setShouldDisplayErrors: Dispatch<boolean>
  nextRequiredModifierGroupID?: string | null
  validateModifiers: () => void
}

export interface OnlineOperationalMenuItemCtxPayload extends OnlineOperationalMenuItem {
  menuGroupXRefID: string
}

type UseHookType = () => ContextType

const MenuItemContext = createContext<ContextType>(null as unknown as ContextType)

const Provider: FunctionComponent<{
  operationalMenu: OperationalMenu
  children?: ReactNode
}> = ({ operationalMenu, children }) => {
  const modifierGroups = useMemo(
    () => operationalMenu?.modifierGroups ?? {},
    [operationalMenu?.modifierGroups]
  )
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState<boolean>(false)
  const [modifiers, setModifiers] = useState<CartItem['childItems']>([])
  const [errors, setErrors] = useState<ModifierValidationErrors>({})
  const [menuItem, setMenuItem] = useState<OnlineOperationalMenuItemCtxPayload | null>(null)
  const [itemIndex, setItemIndex] = useState<number>(-1)
  const [nextRequiredModifierGroupID, setNextRequiredModifierGroupID] = useState<string | null>(
    null
  )

  const sumQuantity = useCallback(
    (currentOptions: OnlineOperationalMenuRefItem[], currentModifierGroupXRefID: string): number =>
      (modifiers ?? [])
        .filter((modifier) =>
          currentOptions.some(
            (modifierOption) =>
              modifier.itemID === modifierOption.id &&
              modifier.modifierGroupXRefID === currentModifierGroupXRefID
          )
        )
        .map((option) => Number(option.quantity))
        .reduce((acc, val) => acc + val, 0),
    [modifiers]
  )

  const validateQuantity = useCallback(
    (item: OnlineOperationalMenuItem): void => {
      const reduceModifierValidations = (
        prevErrors: ModifierValidationErrors,
        currentModifierGroupRef: OnlineOperationalMenuRefItem
      ): ModifierValidationErrors => {
        const modifierGroup = modifierGroups[currentModifierGroupRef.id]
        if (modifierGroup == null) {
          return prevErrors
        }
        const { XRefID, modifierOptionRefs, selectionMin, selectionMax } = modifierGroup

        const quantitySum = sumQuantity(modifierOptionRefs, XRefID)

        // Validates if the number of modifiers fits the max and min allowed
        const isLessThanMinimum = selectionMin > 0 && quantitySum < selectionMin
        const isMoreThanMaximum = selectionMax > 0 && quantitySum > selectionMax

        // Populates the return object with the validation error state
        const isInvalid = isLessThanMinimum || isMoreThanMaximum
        if (isInvalid) {
          return { ...prevErrors, [XRefID]: isInvalid }
        }

        return prevErrors
      }

      setErrors(item.modifierGroupRefs.reduce(reduceModifierValidations, {}))
      item.modifierGroupRefs.every((modifierGroupRef: OnlineOperationalMenuRefItem) => {
        const modifierGroup = modifierGroups[modifierGroupRef.id]
        if (modifierGroup == null || modifierGroup.selectionMin === 0) {
          return true
        }

        const selectedOptions = sumQuantity(modifierGroup.modifierOptionRefs, modifierGroup.XRefID)
        if (selectedOptions < modifierGroup.selectionMin) {
          setNextRequiredModifierGroupID(modifierGroup.XRefID)
        }

        return selectedOptions >= modifierGroup.selectionMin
      })
    },
    [sumQuantity, setErrors, setNextRequiredModifierGroupID, modifierGroups]
  )

  const validateModifiers = useCallback((): void => {
    if (menuItem == null) {
      return
    }

    validateQuantity(menuItem)
  }, [validateQuantity, menuItem])

  const ctxValue = useMemo(
    () => ({
      setShouldDisplayErrors,
      shouldDisplayErrors,
      setModifiers,
      setItemIndex,
      setMenuItem,
      setErrors,
      modifiers,
      itemIndex,
      menuItem,
      errors,
      nextRequiredModifierGroupID,
      validateModifiers,
    }),
    [
      setShouldDisplayErrors,
      shouldDisplayErrors,
      setModifiers,
      setItemIndex,
      setMenuItem,
      setErrors,
      modifiers,
      itemIndex,
      menuItem,
      errors,
      nextRequiredModifierGroupID,
      validateModifiers,
    ]
  )

  useEffect(() => {
    // All the modifier quantity validation magic happens here!!!!!
    validateModifiers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem, nextRequiredModifierGroupID])

  return <MenuItemContext.Provider value={ctxValue}>{children}</MenuItemContext.Provider>
}

export default Provider
export const useMenuItem: UseHookType = () => {
  return useContext(MenuItemContext)
}
