import React, { FunctionComponent, useMemo } from 'react'
import { css } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Grid, SvgIcon, Text } from '@/tbui'

export interface BannerProps {
  type?: 'error' | 'warning' | 'info' | 'success'
  message: React.ReactElement | string
}

const GeneralGrid = styled(Grid)`
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 16px 26px;
`

const StyledText = styled(Text)`
  flex: 1;
  margin-left: 16px;
  text-align: left;
`

const errorGridStyle = (theme: Theme): string => css`
  background: ${theme.palette.ERROR_LIGHT};
  border: 1px solid ${theme.palette.ERROR};
`

const warningGridStyle = (theme: Theme): string => css`
  background: ${theme.palette.WARNING_SECONDARY};
  border: 1px solid ${theme.palette.WARNING_PRIMARY};
`

const infoGridStyle = (theme: Theme): string => css`
  background: ${theme.colors.CLOUD};
  border: 1px solid ${theme.colors.NAVY};
`

const successGridStyle = (theme: Theme): string => css`
  background: ${theme.palette.SUCCESS_LIGHT};
  border: 1px solid ${theme.palette.SUCCESS};
`

const InfoBanner: FunctionComponent<BannerProps> = ({ message, type = 'error' }) => {
  const theme = useTheme()
  const { className, icon } = useMemo((): {
    className: (theme: Theme) => string
    icon: JSX.Element
  } => {
    switch (type) {
      case 'warning': {
        return {
          className: warningGridStyle,
          icon: <SvgIcon name="alert" color="WARNING_PRIMARY" fontSize="24px" />,
        }
      }
      case 'info': {
        return {
          className: infoGridStyle,
          icon: <SvgIcon name="info" color="INFO" fontSize="24px" />,
        }
      }
      case 'success': {
        return {
          className: successGridStyle,
          icon: <SvgIcon name="check_circle" color="SUCCESS" fontSize="24px" />,
        }
      }
      case 'error':
      default: {
        return {
          className: errorGridStyle,
          icon: <SvgIcon name="error" color="ERROR" fontSize="24px" />,
        }
      }
    }
  }, [type])

  return (
    <GeneralGrid className={className(theme)} data-test="info-banner-container">
      {icon}
      <StyledText align="center" color="TEXT" data-test="info-banner-message">
        {message}
      </StyledText>
    </GeneralGrid>
  )
}

export default InfoBanner
