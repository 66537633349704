import { trimAllWhitespace } from './utils'

const validatePostalCode = (value: string): boolean => {
  const postalCode = trimAllWhitespace(value)
  // from https://stackoverflow.com/a/43903635
  const postalRegex = /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i
  const result = postalRegex.test(postalCode)
  return result
}

const validateZipCode = (value: string): boolean => {
  const zipCode = trimAllWhitespace(value)
  // from https://stackoverflow.com/a/160583
  const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  const result = zipCodeRegex.test(zipCode)
  return result
}

const validatePhoneNumber = (value: string): boolean => {
  const phoneNum = trimAllWhitespace(value)
  // lifted from https://www.regexpal.com/95228
  const phoneNumRegex = /^(\(\d{3}\)[.-]?|\d{3}[.-]?)\d{3}[.-]?\d{4}$/
  // only validate when text field has at least one char
  const result = phoneNumRegex.test(phoneNum)
  return result
}

const validateEmail = (value: string): boolean => {
  const email = trimAllWhitespace(value)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const result = emailRegex.test(email)
  return result
}

export { validatePostalCode, validatePhoneNumber, validateZipCode, validateEmail }
