import { FunctionComponent, ChangeEvent, SetStateAction, Dispatch } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useDebounce } from '@/libs/helpers/hooks/useDebounce'
import { useGeolocation } from '@/libs/helpers/hooks/useGeolocation'
import { ConsumerAddress } from '@/libs/helpers/utils'
import { useConfiguration } from '@/providers/configuration/ConfigurationContext'
import { useGoogleMaps, AutocompleteOption } from '@/providers/googleMaps'
import {
  InputAutocomplete,
  CircularLoader,
  Suggestion,
  TextField,
  SvgIcon,
  Divider,
  Text,
  CheckBox,
} from '@/tbui'

export interface UserDeliveryAddressState {
  isValidAddress: boolean
  deliveryAddress: string
  aptNum: string
  useLocation: boolean
  addressXRefID: string | null
  saveDeliveryAddress: boolean
  googlePlaceID: string
}

interface DeliveryAddressInputProps {
  userDeliveryAddress: UserDeliveryAddressState
  setUserDeliveryAddress: Dispatch<SetStateAction<UserDeliveryAddressState>>
  savedAddresses: ConsumerAddress[]
}

const StyledFieldWrapper = styled.div`
  padding: 0.25rem 1rem 0.5rem;
  border: ${(props) => props.theme.shape.BORDER}; // this first
  border-color: ${(props) => props.theme.palette.GRAY_2};
  border-radius: ${(props) => props.theme.shape.RADIUS};
  position: relative;
`

const StyledTextField = styled(TextField)`
  input {
    padding-bottom: 0;
    margin-bottom: 0;
    border: 0;
  }
`
const StyledTextFieldLabel = styled(Text)`
  line-height: 24px;
  font-size: 14px;
`

const StyledPrimaryText = styled.p`
  font-size: 14px;
  margin: 0;
`

const StyledSecondaryText = styled.p`
  font-size: 12px;
  margin: 0;
`

const StyledSvgIcon = styled(SvgIcon)`
  font-size: 20px;
  height: 20px;
  width: 20px;
`

const StyledLabelContainer = styled.div`
  display: flex;
  align-items: center;
  *:not(:first-child) {
    margin-left: 12px;
  }
`

const DeliveryAddressInput: FunctionComponent<DeliveryAddressInputProps> = ({
  userDeliveryAddress,
  setUserDeliveryAddress,
  savedAddresses,
}) => {
  const { userSession } = useConfiguration()
  const { isSignedIn } = userSession
  const { t } = useTranslation()
  const {
    useLocation: useCurrentLocation,
    deliveryAddress: address,
    aptNum,
    googlePlaceID,
  } = userDeliveryAddress

  // browser geolocation permissions
  const { position, isLoading } = useGeolocation()
  // Google API
  const [
    { placesAutocompleteOptions, orderingAddress },
    { getPlacesAutocompleteOptions, selectOrderingAddress, clearUserLocation },
  ] = useGoogleMaps()

  const canSaveDeliveryAddress =
    isSignedIn &&
    userDeliveryAddress.isValidAddress &&
    googlePlaceID !== '' &&
    !savedAddresses.some(
      (savedAddress) =>
        savedAddress.googlePlaceID === googlePlaceID && savedAddress.aptAndSuiteNumber === aptNum
    )

  // HANDLERS
  const handleGeolocation = (): void => {
    clearUserLocation()

    if (useCurrentLocation) {
      setUserDeliveryAddress((userAddress: UserDeliveryAddressState) => ({
        ...userAddress,
        useLocation: false,
        deliveryAddress: '',
      }))
    } else if (position != null) {
      setUserDeliveryAddress((userAddress: UserDeliveryAddressState) => ({
        ...userAddress,
        useLocation: true,
      }))
      selectOrderingAddress({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    }
  }

  // this is debounced at 250ms to reduce calls to google API
  const asyncGetPlacesAutocompleteOptions = useDebounce(
    async (inputValue: string): Promise<void> => {
      // only when user input changes from cached address
      // do we uncheck geolocation checkbox
      if (inputValue !== orderingAddress?.formattedAddress) {
        setUserDeliveryAddress((userAddress: UserDeliveryAddressState) => ({
          ...userAddress,
          useLocation: false,
        }))
      }
      if (inputValue == null || inputValue === '') {
        clearUserLocation()
        setUserDeliveryAddress({
          ...userDeliveryAddress,
          deliveryAddress: '',
          isValidAddress: false,
        })
      } else {
        await getPlacesAutocompleteOptions(inputValue)
      }
    },
    250
  )

  const handleAutocompleteSelection = (selectedSuggestion: Suggestion): void => {
    let placeID = ''

    const savedAddress = savedAddresses.find(
      ({ addressXRefID }) => addressXRefID === selectedSuggestion.key
    )
    if (savedAddress != null) {
      placeID = savedAddress.googlePlaceID
    }

    const googlePlace = placesAutocompleteOptions?.find(({ id }) => id === selectedSuggestion.key)
    if (googlePlace != null) {
      placeID = googlePlace.id
    }

    if (placeID != null && placeID !== '') {
      // TODO: add a isAddressRequired to selectOrderingAddress so we can handle errors properly
      selectOrderingAddress({ placeID })
      setUserDeliveryAddress((userAddress: UserDeliveryAddressState) => ({
        ...userAddress,
        googlePlaceID: placeID,
        addressXRefID: savedAddress != null ? savedAddress.addressXRefID : null,
        deliveryAddress:
          savedAddress != null ? savedAddress.formattedAddress : userAddress.deliveryAddress,
        aptNum: savedAddress?.aptAndSuiteNumber ?? '',
        isValidAddress: true,
      }))
    } else {
      setUserDeliveryAddress((userAddress: UserDeliveryAddressState) => ({
        ...userAddress,
        isValidAddress: false,
        googlePlaceID: '',
      }))
    }
  }

  const mapAddressOptions = (options: AutocompleteOption[]): Suggestion[] => {
    return options?.map((option) => ({
      value: option.label,
      key: option.id,
      label: (
        <>
          <StyledPrimaryText>{option.primaryText}</StyledPrimaryText>
          <StyledSecondaryText>{option.secondaryText}</StyledSecondaryText>
        </>
      ),
    }))
  }

  const mapSavedAddressOptions = (options: ConsumerAddress[]): Suggestion[] => {
    return options.map((addr) => ({
      value: addr.formattedAddress,
      key: addr.addressXRefID,
      label: (
        <StyledLabelContainer>
          <StyledSvgIcon name="star" fontSize="28px" color="PRIMARY" />
          <StyledPrimaryText>
            {addr.aptAndSuiteNumber != null &&
              addr.aptAndSuiteNumber.length > 0 &&
              t('order_method_selector.aptnum_saved_address', {
                aptNum: addr.aptAndSuiteNumber,
              })}
            {addr.formattedAddress}
          </StyledPrimaryText>
        </StyledLabelContainer>
      ),
    }))
  }

  return (
    <>
      <div css={{ display: 'grid', gap: '1rem' }}>
        <div css={{ display: 'grid', gap: '0.5rem', gridTemplateColumns: 'auto 1fr' }}>
          <SvgIcon name="location" color="PRIMARY" fontSize="20px" />
          <Text type="H4" bold>
            {t('order_method_selector.subheader_delivery')}
          </Text>
        </div>
        <div>
          <div css={{ display: 'grid', gap: '0.5rem' }}>
            <StyledFieldWrapper data-test="order-method-method-input-address">
              <InputAutocomplete
                label={t('order_method_selector.address_input')}
                suggestions={mapAddressOptions(placesAutocompleteOptions ?? [])}
                // static suggestions props used to display saved addresses
                staticSuggestions={mapSavedAddressOptions(savedAddresses)}
                staticSuggestionsHeader={t('order_method_selector.saved_addresses')}
                onChange={asyncGetPlacesAutocompleteOptions}
                onSelect={handleAutocompleteSelection}
                data-test="input-address"
                value={address}
                variant="INSET"
                data-dd-privacy="mask"
              />
            </StyledFieldWrapper>
            <StyledFieldWrapper>
              <StyledTextField
                label={t('order_method_selector.aptnum_input')}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setUserDeliveryAddress({
                    ...userDeliveryAddress,
                    aptNum: e.target.value,
                  })
                }}
                data-test="input-apt-num"
                variant="INSET"
                name="apt-num"
                value={aptNum}
              />
            </StyledFieldWrapper>
          </div>
          {canSaveDeliveryAddress && (
            <div
              css={{
                display: 'grid',
                gap: '12px',
                padding: '1rem 1rem .75rem 0',
                gridTemplateColumns: 'auto 1fr',
              }}
              data-test="save-delivery-address"
            >
              <CheckBox
                variant="SQUARE"
                iconSize="24px"
                id="save-delivery-address"
                name="save-delivery-address"
                isSelected={userDeliveryAddress.saveDeliveryAddress}
                iconColor={userDeliveryAddress.saveDeliveryAddress ? 'PRIMARY' : 'GRAY_2'}
                onChange={() => {
                  setUserDeliveryAddress({
                    ...userDeliveryAddress,
                    saveDeliveryAddress: !userDeliveryAddress.saveDeliveryAddress,
                  })
                }}
                data-test="save-delivery-address-checkbox"
              />
              <StyledTextFieldLabel>{t('order_method_selector.save_address')}</StyledTextFieldLabel>
            </div>
          )}
        </div>
      </div>
      <Divider />
      {position != null && (
        <>
          <div
            css={{
              display: 'grid',
              gap: '1rem',
              gridTemplateColumns: 'auto 1fr auto',
              alignItems: 'center',
            }}
            onClick={handleGeolocation}
            role="button"
            data-test="current-location"
          >
            <SvgIcon name="location_alt" fontSize="20px" color="PRIMARY" />
            <div>
              <div
                css={{
                  display: 'grid',
                  gap: '1rem',
                  gridTemplateColumns: 'auto 1fr',
                  align: 'center',
                }}
              >
                <Text bold color="PRIMARY">
                  {t('order_method_selector.current_location')}
                </Text>
                {isLoading && useCurrentLocation && <CircularLoader fontSize="20px" />}
              </div>
              {orderingAddress && useCurrentLocation && (
                <Text type="H6" m="0" data-test="current-location-address">
                  {orderingAddress?.formattedAddress}
                </Text>
              )}
            </div>

            <div
              style={{
                // Needed so we don't trigger the checkbox
                pointerEvents: 'none',
              }}
            >
              <CheckBox
                id="use-current-location"
                variant="SQUARE"
                iconSize="24px"
                isSelected={useCurrentLocation}
              />
            </div>
          </div>
          <Divider />
        </>
      )}
    </>
  )
}

export default DeliveryAddressInput
