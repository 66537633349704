import { FunctionComponent, MutableRefObject, useState } from 'react'
import { css, cx } from '@emotion/css'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import Big from 'big.js'
import { useTranslation } from 'react-i18next'
import type {
  OnlineOperationalModifierGroup,
  OnlineOperationalModifierOptions,
} from '@/libs/helpers/adapters'
import { Grid } from '@/tbui'
import { textStyles } from '@/tbui/Text/styles'
import ModifierOption from './ModifierOption'
import { useMenuItem } from './context'

const StyledModifierGroup = styled.div<{ hasError?: boolean }>`
  background: ${(props) =>
    props.hasError ? props.theme.palette.ERROR_LIGHT : props.theme.palette.WHITE};
  padding: 1rem;
  display: grid;
`

const groupNameStyles = (theme: Theme): string => css`
  ${textStyles(theme).heading.three};
  text-transform: capitalize;

  &.has-error {
    color: ${theme.palette.ERROR_TEXT};
  }
`

const messageStyles = (theme: Theme): string => css`
  ${textStyles(theme).text.five};
  text-transform: uppercase;
  margin: 5px 0 0 0;
  color: ${theme.palette.GRAY_3};

  &.has-error {
    color: ${theme.palette.ERROR_TEXT};
  }
`

interface ModifierGroupProps {
  modifierGroup: OnlineOperationalModifierGroup
  hasError: boolean
  modifierGroupRefs: MutableRefObject<Record<string, HTMLDivElement>>
  calculatePrice: () => void
  modifierOptions: OnlineOperationalModifierOptions
}

const ModifierGroup: FunctionComponent<ModifierGroupProps> = ({
  modifierGroup,
  hasError,
  modifierGroupRefs,
  calculatePrice,
  modifierOptions,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const renderRequiredMessage = (): string => {
    const hasMin = Boolean(modifierGroup.selectionMin)
    const hasMax = Boolean(modifierGroup.selectionMax)

    if (hasMin || hasMax) {
      return t(`menu_item.modifiers.validation.${hasMin}_${hasMax}`, {
        count: modifierGroup.selectionMax || modifierGroup.selectionMin,
        min: modifierGroup.selectionMin,
        max: modifierGroup.selectionMax,
      })
    }
    return ''
  }

  const titleColor = hasError ? 'ERROR' : 'TEXT'
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const { modifiers, validateModifiers } = useMenuItem()

  const checkSelectionMax = (): void => {
    if (modifiers && modifierGroup.selectionMax) {
      const quantitySum = modifiers
        .filter((modifier) => modifier.modifierGroupXRefID === modifierGroup.XRefID)
        .reduce((acc, val) => Big(acc).plus(Big(val.quantity)), Big(0))
      if (quantitySum.gte(modifierGroup.selectionMax)) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }
  }

  const checkModifiers = (): void => {
    validateModifiers()
    calculatePrice()
    checkSelectionMax()
  }

  const renderModifierOptions = (): JSX.Element[] => {
    const modifierOptionsToRender: JSX.Element[] = []
    for (const modifierOptionRef of modifierGroup.modifierOptionRefs) {
      const selectionId = `${modifierGroup.XRefID}_${modifierOptions[modifierOptionRef.id].XRefID}`
      modifierOptionsToRender.push(
        <ModifierOption
          key={selectionId}
          selectionId={selectionId}
          modifierOption={modifierOptions[modifierOptionRef.id]}
          isDisabled={isDisabled}
          checkModifiers={checkModifiers}
          selectionMax={modifierGroup.selectionMax}
          modifierGroupID={modifierGroup.XRefID}
        />
      )
    }
    return modifierOptionsToRender
  }

  return (
    <StyledModifierGroup
      hasError={hasError}
      data-test="modifier-group-section"
      ref={(modGroup) => {
        // only required modifier groups can be scrolled to
        if (modifierGroup.selectionMin > 0 && modGroup != null) {
          // eslint-disable-next-line no-param-reassign
          modifierGroupRefs.current[modifierGroup.XRefID] = modGroup
        }
      }}
    >
      <Grid>
        <h3
          className={cx(groupNameStyles(theme), `${hasError ? 'has-error' : ''}`)}
          color={titleColor}
          data-test={`modifier-group-${modifierGroup.XRefID}-title`}
        >
          {modifierGroup.label}
        </h3>
        <h5
          color={titleColor}
          className={cx(messageStyles(theme), `${hasError ? 'has-error' : ''}`)}
          data-test={`modifier-group-${modifierGroup.XRefID}-subtitle`}
        >
          {renderRequiredMessage()}
        </h5>
      </Grid>
      {renderModifierOptions()}
    </StyledModifierGroup>
  )
}

export default ModifierGroup
