import { FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { buildAssetsPath } from '@/libs/helpers/assets'
import { Grid, Text } from '@/tbui'

const StyledTBLogo = styled.img`
  height: 18px;
  width: auto;
`

const GuestCheckoutFooter: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <Grid
      container
      justify="center"
      align="center"
      template={'auto auto'}
      gap={'6px'}
      data-test="guest-checkout-footer"
    >
      <Text color="TEXT_2" fontSize="10px" style={{ marginTop: '2px' }}>
        {t('guest_checkout.powered_by')}
      </Text>
      <StyledTBLogo src={buildAssetsPath('/images/tb-logo-small.png')} alt="logo" />
    </Grid>
  )
}

export default GuestCheckoutFooter
