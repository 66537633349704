import { ChangeEvent, FunctionComponent, ReactElement, useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import type { LoyaltySessionState } from '@/components/Checkout'
import type { EvaluateLoyaltyRewards, LoyaltyReward } from '@/libs/helpers/apiClient'
import { useModal } from '@/providers/modal'
import { Button, Grid, Radio, SvgIcon, Text, textStyles } from '@/tbui'
import PulsateLoader from '../PulsateLoader'

export interface ApplyLoyaltyRewardButtonProps {
  loyaltyTransaction: EvaluateLoyaltyRewards
  venueRewards: LoyaltyReward[]
  loyaltySession: LoyaltySessionState
  onSelectReward: (selectedReward: string | null) => Promise<void>
}

const StyledGrid = styled(Grid)<{ isLoading: boolean }>`
  border-radius: ${(props) => props.theme.shape.RADIUS};
  background-color: ${(props) => props.theme.palette.SECONDARY};
  margin-bottom: 24px;
  ${(props) => props.isLoading && { justifyItems: 'center' }};
  gap: ${(props) => (props.isLoading ? '0' : '5rem')};
`

const StyledRewardName = styled.span<{ isDisabled: boolean }>`
  ${({ theme }) => textStyles(theme).text.p}
  font-size: 16px;
  color: ${(props) => props.theme.palette.TEXT};
  display: block;
`

const StyledDescription = styled.span`
  ${({ theme }) => textStyles(theme).text.p}
  color: ${({ theme }) => theme.palette.GRAY_3};
  display: block;
  margin-top: 5px;
`

const RewardWrapper = styled.span`
  display: block;
`

const radioStyles = css`
  label {
    // same default margin bottom on all breakpoints overrule tbui radio
    margin-bottom: 10px;
  }
`

const StyledText = styled(Text)`
  font-family: ${(props) => props.theme.font.FAMILY_BOLD};
  color: ${(props) => props.theme.palette.TEXT_SECONDARY};
  width: 100%;
`

const StyledSvgIcon = styled(SvgIcon)`
  align-self: center;
`

const ApplyLoyaltyRewardButton: FunctionComponent<ApplyLoyaltyRewardButtonProps> = ({
  loyaltyTransaction,
  venueRewards,
  loyaltySession,
  onSelectReward,
}) => {
  // HOOKS
  const theme = useTheme()
  const { t } = useTranslation()
  const { openModal, closeModal } = useModal()

  // CONST
  const { loyalty, selectedRewardXRefID: rewardToBeRedeemed } = loyaltyTransaction
  const isRewardRedeemed = rewardToBeRedeemed != null
  const userHasRewards = (loyalty != null && loyalty.rewards.length > 0) ?? false
  const rewardsForModal = (userHasRewards ? loyalty?.rewards : venueRewards) ?? []
  const isSessionLoading = loyaltySession.isLoyaltySessionLoading

  // STATES
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // local state for Radio component that contains the nullable selected reward XRefID
  const [selectedReward, setSelectedReward] = useState<string | null>(rewardToBeRedeemed ?? null)

  const isRewardDisabled = (reward: LoyaltyReward): boolean =>
    !userHasRewards || reward.rewardType == null || reward.discountAmount === '0.00'
  // TODO: check if 'points' should be translated on menu page modal & here
  const rewardTextToShow = (reward: LoyaltyReward): string =>
    userHasRewards ? reward.description ?? '' : `${reward.points} points`

  const rewardOption = (reward: LoyaltyReward): ReactElement => {
    return (
      <RewardWrapper>
        <StyledRewardName isDisabled={isRewardDisabled(reward)}>{reward.name}</StyledRewardName>
        <StyledDescription>{rewardTextToShow(reward)}</StyledDescription>
      </RewardWrapper>
    )
  }

  const rewardToDisplay = loyalty?.rewards.find(
    (reward) => reward.rewardXRefID === rewardToBeRedeemed
  )

  const applyRewardButtonLabel =
    isRewardRedeemed && rewardToDisplay != null
      ? t('loyalty_reward.apply.selected_reward', {
          rewardName: rewardToDisplay.name,
        })
      : t('loyalty_reward.apply.button')

  const handleRedeemReward = async (): Promise<void> => {
    // closing modal first, otherwise in case of modal-handled errors,
    // the error modal will be closed and we won't see the error
    closeModal()
    setIsOpen(false)
    await onSelectReward(selectedReward)
  }

  useEffect(() => {
    if (isOpen) {
      openModal(
        <Grid data-test="loyalty-rewards-modal" p="0 20px" gap="24px">
          <div>
            <h3 css={[textStyles(theme).heading.two, { margin: '0 5px 8px 0', fontSize: '20px' }]}>
              {t('loyalty_reward.apply.apply_your_reward')}
            </h3>
            <p css={textStyles(theme).text.p}>
              {userHasRewards
                ? t('loyalty_reward.apply.add_reward_subtitle')
                : t('loyalty_reward.apply.add_reward_subtitle_no_rewards')}
            </p>
          </div>
          <Grid template="25px 1fr" justify="center">
            <SvgIcon name="gift" fontSize="18px" color="PRIMARY" />
            <p css={textStyles(theme).heading.p} data-test="current-points">
              {t('loyalty_reward.apply.current_points', {
                points:
                  loyalty?.balances?.find((balance) => balance.label === 'points')?.currentValue ??
                  '0',
              })}
            </p>
          </Grid>
          <div className={radioStyles}>
            <Radio
              onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                e.preventDefault()
                setSelectedReward(e.target.value)
              }}
              options={rewardsForModal.map((reward: LoyaltyReward) => ({
                label: rewardOption(reward),
                value: reward.rewardXRefID,
                key: reward.rewardXRefID,
                disabled: isRewardDisabled(reward),
              }))}
              name="redeemable-rewards"
              data-test="loyalty-rewards-modal-radio"
              selected={selectedReward ?? undefined}
              showDivider
            />
          </div>
        </Grid>,
        {
          variant: 'DEFAULT',
          scrollBehavior: 'SCROLL',
          onClose: (): void => {
            setSelectedReward(rewardToBeRedeemed ?? null)
            setIsOpen(false)
            closeModal()
          },
          footer: (
            <Button
              disabled={selectedReward == null || isSessionLoading || !userHasRewards}
              data-test="redeem-rewards-button"
              type="submit"
              onClick={handleRedeemReward}
              css={{ margin: '0 20px' }}
              isLoading={isSessionLoading}
            >
              {t('loyalty_reward.apply.redeem_reward')}
            </Button>
          ),
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedReward])

  if (loyalty == null) {
    return null
  }

  return (
    <StyledGrid
      template="1fr auto"
      gap="5rem"
      p="15px"
      isLoading={isSessionLoading}
      data-test="loyalty-container"
    >
      <StyledText
        p="5px"
        align="left"
        data-test="apply-loyalty-reward-button"
        onClick={() => setIsOpen((open) => !open)}
      >
        {!isSessionLoading ? applyRewardButtonLabel : <PulsateLoader height="18px" />}
      </StyledText>
      <StyledSvgIcon
        name={isRewardRedeemed ? 'cross_circle' : 'add_circle'}
        fontSize="25px"
        color="TEXT_SECONDARY"
        disabled={isSessionLoading}
        onClick={async () => {
          if (!isRewardRedeemed) {
            setIsOpen(true)
            return
          }
          // null means that we're removing the reward from the session
          // i.e the reward is no longer being applied
          await onSelectReward(null)
          setSelectedReward(null)
        }}
      />
    </StyledGrid>
  )
}

export default ApplyLoyaltyRewardButton
